import React from 'react';
import Modal from "react-bootstrap/Modal";
import PackageCategoryForm from "./PackageCategoryForm";
import axios from "axios";
import toast from "react-hot-toast";

const CreatePackageCat = ({isOpen, onClose, refresh}) => {


    const createPackageCategory = (formData) => {

        axios
            .post(`${process.env.REACT_APP_API_BASE_URL}/package-category`, formData)
            .then((response) => {
                // console.log(response.data);
                console.log(response)

                if (response.data.status === "OK") {
                    toast.success("Created Successfully")
                    onClose()
                    refresh();
                }

            })
            .catch((err) => console.log(err));
    }


    return (
        <Modal show={isOpen} onHide={onClose}>
            <Modal.Header><h5>Create New Package Category</h5></Modal.Header>
            <Modal.Body>
                <PackageCategoryForm onClose={onClose} apiHandler={createPackageCategory}/>
            </Modal.Body>

        </Modal>
    );
};

export default CreatePackageCat;