import React, { useState } from "react";
import useFetch from "../../hooks/useFetch";
import request from "../../request/request";
import EditButton from "../Button/EditButton";
import DeleteButton from "../Button/DeleteButton";
import CardLayout from "./CardLayout";
import ReactTable from "../ReactDataTable/ReactTable";
import ModalLayout from "./ModalLayout";

import DeleteModal from "../Modal/DeleteModal";
import FormLayout from "./FormLayout";

const CrudLayout = ({ config }) => {
  const [modalOpen, setModalOpen] = useState(""); // create, update, delete
  const [selectedItemId, setSelectedItemId] = useState("");
  const [initialValues, setInitialValues] = useState(config?.initialValues);

  // data fetch

  const { result, isLoading, isSuccess, error, setRefresh } = useFetch(() =>
    request.getAll(config?.link),
  );

  const handlerOnDelete = async () => {
    await request.delete(config?.link, selectedItemId, () => {
      setRefresh((prevState) => !prevState);
      setModalOpen("");
    });
  };

  const handleEdit = (data) => {
    setSelectedItemId(data.id);
    setModalOpen("update");
    setInitialValues(data);
  };

  const editDeleteAction = {
    name: "Action",
    sortable: "false",
    center: "true",
    cell: (data) => (
      <>
        {/* Button trigger modal */}
        <EditButton onClick={() => handleEdit(data)} />
        <DeleteButton
          onClick={() => {
            setSelectedItemId(data.id);
            setModalOpen("delete");
          }}
        />
      </>
    ),
  };

  const modalClose = () => setModalOpen("");

  const apiRefresh = () => setRefresh((prevState) => !prevState);

  const updateHandler = async (values) => {
    await request.updateOne(config?.link, values?.id, values, () => {
      modalClose();
      apiRefresh();
    });
  };

  const createNewCategory = async (formData) => {
    await request.create(config?.link, formData, () => {
      modalClose();
      apiRefresh();
    });
  };

  const apiHandler = async (values) => {
    if (modalOpen === "create") {
      await createNewCategory(values);
    } else if (modalOpen === "update") {
      await updateHandler(values);
    }
  };

  return (
    <section>
      {/* Content show */}

      <CardLayout
        title={config?.table_name}
        buttonTitle={config?.create_modal_title}
        addButtonHandler={() => {
          setModalOpen("create");
          setInitialValues(config?.initialValues);
        }}
      >
        <ReactTable
          columns={[...config.columns, editDeleteAction]}
          data={isSuccess ? result : []}
          pending={isLoading}
        />
      </CardLayout>

      {/* create modal */}
      {(modalOpen === "create" || modalOpen === "update") && (
        <ModalLayout
          title={
            modalOpen === "create"
              ? config?.create_modal_title
              : config?.update_modal_title
          }
          onClose={modalClose}
          isOpen={modalOpen === "create" || modalOpen === "update"}
        >
          <FormLayout
            apiHandler={apiHandler}
            onClose={modalClose}
            initialValues={initialValues}
          >
            {config.form}
          </FormLayout>
        </ModalLayout>
      )}

      {/* delete modal */}
      {modalOpen === "delete" && (
        <DeleteModal
          isOpen={modalOpen === "delete"}
          onClose={modalClose}
          handleDelete={handlerOnDelete}
        />
      )}
    </section>
  );
};

export default CrudLayout;
