import { Form, InputGroup } from "react-bootstrap";
import FormLabel from "../../../components/Text/FormLabel";
import ReactInput from "../../../components/InputList/ReactInput/ReactInput";
import { useFormikContext } from "formik";

const SocialMediaForm = () => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <div>
      <InputGroup className="mt-3">
        <FormLabel title={"Name"} required />
        <ReactInput name="name" required />
      </InputGroup>
      <InputGroup className="mt-3">
        <FormLabel title={"Icon"} required />
        <ReactInput name="icon" required />
      </InputGroup>
      <InputGroup className="mt-3">
        <FormLabel title={"Link"} required />
        <ReactInput name="link" required />
      </InputGroup>
      <InputGroup className="mt-3">
        <Form.Check
          label="status"
          checked={values.status === 1}
          onChange={(e) => setFieldValue("status", e.target.checked ? 1 : 0)}
        />
      </InputGroup>
    </div>
  );
};
export default SocialMediaForm;
