import React from 'react';
import RoomInputForm from "./RoomInputForm";
import toast from "react-hot-toast";
import axios from "axios";

const AddNewRoom = ({isOpen, onClose, accommodationList, hospitalList, refresh}) => {

    const createData = (data) => {

        if (!data.name) {
            toast.error('Name can not be empty!');
        } else {
            axios
                .post(`${process.env.REACT_APP_API_BASE_URL}/room`, data)
                .then((response) => {
                    if (response.data.status === 'OK') {
                        toast.success("Room created successfully");
                        refresh();
                        onClose();
                    }
                })
                .catch((err) => console.log(err));

        }
    };

    return (
        <div>
            <RoomInputForm
                onClose={onClose}
                isOpen={isOpen}
                accommodationList={accommodationList}
                hospitalList={hospitalList}
                apiHandler={createData}
            />

        </div>
    );
};

export default AddNewRoom;