import React from 'react';
import useFetchData from "../../hooks/useFetchData";
import CardLayout from "../Layout/CardLayout";
import ReactTable from "../ReactDataTable/ReactTable";
import formatDateTime from "../../utils/DateTimeFormate";
import {getMedicineRequestStatus} from "../../utils/data/requestStatus";

const MedicineRequest = ({user_id, role_id}) => {
    const {data: medicineRequest} = useFetchData(`services/request/drug/user/${user_id}`)

    const firstPart = [
        {
            key: "req_no",
            text: "Request No:",
            align: "center",
            sortable: true,
        },
        {
            key: "req_type",
            text: "Request Type",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (row) => {
                return (
                    <span>
            {row.req_type === 1
                ? "General"
                : row.req_type === 2
                    ? "Selective"
                    : "unknown"}
          </span>
                );
            },
        },
        {
            key: "select_type",
            text: "Select Type",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (row) => {
                return (
                    <span>
            {row.req_type === 2
                ? "Prescription Pictures"
                : row.req_type === 1
                    ? "Medicines"
                    : "unknown"}
          </span>
                );
            },
        },
    ]

    const medicineShopInfo = [
        {
            key: "confirm_medicine_shop_id",
            text: "Pharmacy Name",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (row) => {
                return <span>{row?.confirm_medicine_shop?.f_name}</span>;
            },
        },
        {
            key: "confirm_medicine_shop_mobile",
            text: "Pharmacy Mobile",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (row) => {
                return <span>{row?.confirm_medicine_shop?.mobile}</span>;
            },
        },
    ]

    const deliveryPersionInfo = [

        {
            key: "delivery_person_id",
            text: "Delivery Person",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (row) => {
                return <span>{row.delivery_person?.f_name}</span>;
            },
        },

        {
            key: "delivery_person_mobile",
            text: "Delivery Person",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (row) => {
                return <span>{row.delivery_person?.mobile}</span>;
            },
        },
    ]

    const medicineRequesterInfo = [
        {
            key: "req_by",
            text: "Request By",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => {
                return <span>{data?.drug_requester?.f_name}</span>;
            },
        },
        {
            key: "mobile",
            text: "Requester Mobile",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => {
                return <span>{data?.drug_requester?.mobile}</span>;
            },
        },
    ]

    const lastPart = [


        {
            key: "createdAt",
            text: "Request Date",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (row) => {
                return <span>{formatDateTime(row?.createdAt)}</span>;
            },
        },

        {
            key: "status",
            text: "status",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (row) => {
                return (
                    <span>{getMedicineRequestStatus(row?.status)}</span>
                );
            },
        },
    ];

    let columns = [];

    if (role_id === 10) {
        columns = [...columns, ...firstPart, ...medicineShopInfo, ...deliveryPersionInfo, ...lastPart]
    } else if (role_id === 16) {
        columns = [...columns, ...firstPart, ...medicineRequesterInfo, ...deliveryPersionInfo, ...lastPart]
    } else if (role_id === 12) {
        columns = [...columns, ...firstPart, ...medicineRequesterInfo, ...medicineShopInfo, ...lastPart]
    }


    console.log(medicineRequest?.data);


    return (
        <section>
            <CardLayout title={"Medicine Request"}>
                <ReactTable
                    data={medicineRequest?.data}
                    columns={columns}
                />
            </CardLayout>

        </section>
    );
};

export default MedicineRequest;