import React from 'react';
import {Button, Form, Modal} from 'react-bootstrap';

const RoomInputForm = ({isOpen, onClose, accommodationList, hospitalList, apiHandler, editData}) => {

    const formHandler = (event) => {
        event.preventDefault();
        const form = event.target;
        const data = {
            name: form.name.value,
            floor: form.floor.value,
            accommodation_type_id: form.accommodation_type.value,
            hospital_id: form.hospital.value,
            price: form.price.value,
            info: form.info.value,
            status: form.checkbox.checked ? 1 : 0
        }
        apiHandler(data);

    }
    console.log(editData)
    return (
        <Modal
            show={isOpen}
            onHide={onClose}
            size='lg'
        >


            <Modal.Header closeButton={true}>
                <Modal.Title>{editData ? "Update Room" : "Add New Room"} </Modal.Title>
            </Modal.Header>
            <Modal.Body className='m-2'>
                <Form onSubmit={formHandler}>
                    <Form.Group className='mb-3 row' controlId='inputName'>
                        <Form.Label className='col-sm-3 col-form-label d-flex justify-content-start'>
                            Name <span style={{color: 'red'}}>*</span>
                        </Form.Label>
                        <Form.Control
                            name='name'
                            type='text'
                            defaultValue={editData ? editData?.name : ""}
                            required
                        />
                    </Form.Group>
                    <Form.Group className='mb-3 row' controlId='inputFloor'>
                        <Form.Label className='col-sm-3 col-form-label d-flex justify-content-start'>
                            Floor
                        </Form.Label>
                        <Form.Control name='floor' type='text' defaultValue={editData ? editData?.floor : ""}/>
                    </Form.Group>
                    <Form.Group className='mb-3 row' controlId='inputAccommodationType'>
                        <Form.Label className='col-sm-3 col-form-label d-flex justify-content-start'>
                            Accommodation Type
                        </Form.Label>
                        <Form.Control as='select' name='accommodation_type'
                                      defaultValue={editData ? editData?.accommodation_type_id : ""}>
                            {/* Add options for Accommodation Type */}
                            <option value="" disabled={true} selected> Select Accommodation Type</option>
                            {
                                accommodationList.map(accommodation => (
                                    <option key={accommodation?.id}
                                            value={accommodation?.id}
                                            selected={editData && editData.accommodation_type_id}
                                    >
                                        {accommodation?.name}
                                    </option>
                                ))
                            }
                        </Form.Control>
                    </Form.Group>
                    <Form.Group className='mb-3 row' controlId='inputHospital'>
                        <Form.Label className='col-sm-3 col-form-label d-flex justify-content-start'>
                            Hospital
                        </Form.Label>
                        <Form.Control as='select' name='hospital'>
                            {/* Add options for Hospital */}
                            <option value="" disabled={true} selected> Select Hospital</option>
                            {
                                hospitalList.map(accommodation => (
                                    <option key={accommodation?.id}
                                            value={accommodation?.id}
                                            selected={editData && editData?.hospital_id}
                                    >
                                        {accommodation?.f_name}
                                    </option>
                                ))
                            }
                        </Form.Control>
                    </Form.Group>
                    <Form.Group className='mb-3 row' controlId='inputPriceNight'>
                        <Form.Label className='col-sm-3 col-form-label d-flex justify-content-start'>
                            Price/Night
                        </Form.Label>
                        <Form.Control
                            name='price'
                            type='number'
                            defaultValue={editData && editData?.price}
                        />
                    </Form.Group>
                    <Form.Group className='mb-3 row' controlId='inputInfo'>
                        <Form.Label className='col-sm-3 col-form-label d-flex justify-content-start'>
                            Info
                        </Form.Label>
                        <Form.Control
                            name='info'
                            as='textarea'
                            rows={2}
                            defaultValue={editData && editData?.info}
                        />
                    </Form.Group>
                    <Form.Group className='mb-3 row'>
                        <div className='col-sm-12'>
                            <div className=' d-flex justify-content-end align-items-center'>

                                <Form.Check
                                    className='mt-0 me-2'
                                    type='checkbox'
                                    name={'checkbox'}
                                    label={'Active'}
                                    defaultChecked={editData && (editData.status === 1)}
                                />

                            </div>
                        </div>
                    </Form.Group>

                    <div className=' d-flex justify-content-end align-items-center gap-2'>
                        <Button
                            type='button'
                            className='btn btn-secondary'
                            onClick={onClose}
                        >
                            Close
                        </Button>
                        <Button
                            type='submit'
                            className='btn btn-primary'
                            data-bs-dismiss='modal'

                        >
                            Save
                        </Button>
                    </div>

                </Form>
            </Modal.Body>


        </Modal>
    );
};

export default RoomInputForm;
