import React from "react";
import { Button } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";

const EditButton = ({ onClick }) => (
  <Button className="me-3" onClick={onClick} variant="primary" size="sm">
    <FaEdit />
  </Button>
);

export default EditButton;
