import React from 'react';
import DeleteModal from "../../../components/Modal/DeleteModal";
import axios from "axios";
import {toast} from "react-hot-toast";

const DeleteAccommodation = ({isOpen, onClose, data, refresh}) => {

    const handleDelete = () => {
        axios.delete(`${process.env.REACT_APP_API_BASE_URL}/settings/accommodation/${data?.id}`,)
            .then(res => {
                if (res.data.status === "OK") {
                    toast.success("Accommodation Deleted successfully")
                    onClose();
                    refresh();
                }
                console.log(res.data)
            })
            .catch(err => console.log(err))
    }
    return (
        <div>
            <DeleteModal
                onClose={onClose}
                isOpen={isOpen}
                handleDelete={() => handleDelete()}
            />
        </div>
    );
};

export default DeleteAccommodation;