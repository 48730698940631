import React from 'react';
import {Col, Form, FormCheck, FormControl, FormGroup,} from "react-bootstrap";
import Button from "react-bootstrap/Button";

const PackageCategoryForm = ({onClose, apiHandler, editData}) => {


    const formHandler = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        const formData = new FormData(form);

        const data = {
            ...Object.fromEntries(formData),
            multi_time_subscription: form?.multi_time_subscription?.checked ? 1 : 0,
            status: form?.status?.checked ? 1 : 0,
        }
        apiHandler(data)

    }

    return (
        <Form onSubmit={formHandler}>
            <FormGroup className="mb-3 row">
                <Form.Label htmlFor="inputName" className="col-sm-3 col-form-label d-flex justify-content-start">
                    Name <span style={{color: "red"}}>*</span>
                </Form.Label>
                <Col sm={9}>
                    <FormControl
                        onChange={onchange}
                        name="name"
                        type="text"
                        id="inputName"
                        required
                        defaultValue={editData && editData?.name}
                    />
                </Col>
            </FormGroup>

            {/* Icon */}
            <FormGroup className="mb-3 row">
                <Form.Label htmlFor="icon" className="col-sm-3 col-form-label d-flex justify-content-start">
                    Icon <span style={{color: "red"}}>*</span>
                </Form.Label>
                <Col sm={9}>
                    <FormControl
                        onChange={onchange}
                        name="icon"
                        type="text"
                        id="icon"
                        placeholder="Icon name from React Icons"
                        defaultValue={editData && editData?.icon}
                    />
                </Col>
            </FormGroup>

            <FormGroup className="mb-3 row">
                <Form.Label htmlFor="inputInfo" className="col-sm-3 col-form-label d-flex justify-content-start">
                    Info
                </Form.Label>
                <Col sm={9}>
                    <FormControl
                        as="textarea"
                        onChange={onchange}
                        name="info"
                        id="inputInfo"
                        rows={2}
                        defaultValue={editData && editData?.info}
                    />
                </Col>
            </FormGroup>

            <FormGroup className="mb-3 row">
                <Col sm={12} className="d-flex justify-content-end gap-5">
                    <div className="form-check d-flex justify-content-end align-items-center">
                        <FormCheck
                            name='multi_time_subscription'
                            type="checkbox"

                            id="flexCheckCheckedMulti"
                            label="Multi Time Subscription"
                            defaultChecked={editData && editData?.multi_time_subscription === 1}
                        />
                    </div>

                    <div className="form-check d-flex justify-content-end align-items-center">
                        <FormCheck
                            name='status'
                            type="checkbox"
                            id="flexCheckCheckedActive"
                            label="Active"
                            defaultChecked={editData && editData?.status === 1}
                        />
                    </div>
                </Col>
            </FormGroup>

            <Col className='d-flex gap-4 justify-content-end'>
                <Button type='submit'>Submit</Button>
                <Button variant='secondary' onClick={onClose}>Close</Button>
            </Col>

        </Form>
    );
};

export default PackageCategoryForm;