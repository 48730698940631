import { useEffect, useState } from "react";
import CardLayout from "../../../components/Layout/CardLayout";
import ReactTable from "../../../components/ReactDataTable/ReactTable";
import axios from "axios";
import toast from "react-hot-toast";
import { Button } from "react-bootstrap";
import DeleteModal from "../../../components/Modal/DeleteModal";
import DeliveryChargeForm from "./DeliveryChargeForm";

const DeliveryCharge = () => {
  const [modalOpen, setModalOpen] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [deliveryChargeData, setDeliveyChargeData] = useState([]);

  useEffect(() => {
    (async function () {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/delivery-charge`,
        );

        if (data?.status === "OK") {
          setDeliveyChargeData(data?.data);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [refresh]);

  const columns = [
    {
      key: "module_name",
      text: "Module Name",
    },
    {
      key: "module_key",
      text: "Module key",
    },
    {
      key: "delivery_charge",
      text: "Delivery Charge",
    },
    {
      key: "min_amount_free_delivery",
      text: "Min Amount for free Delivery",
    },
    {
      key: "status",
      text: "status",
      cell: (row) => (row?.status === 1 ? "Active" : "Inactive"),
    },
    {
      key: "action",
      text: "Action",
      cell: (row) => (
        <div>
          <Button
            size="sm"
            onClick={() => {
              setModalOpen("update");
              setSelectedItem(row);
            }}
          >
            <i className="fa fa-edit"></i>
          </Button>

          <Button
            size="sm"
            className="ms-2"
            variant="danger"
            onClick={() => {
              setModalOpen("delete");
              setSelectedItem(row);
            }}
          >
            <i className="fa fa-trash"></i>
          </Button>
        </div>
      ),
    },
  ];

  const onClose = () => {
    setModalOpen("");
    setSelectedItem(null);
  };

  const addNewDeliveryCharge = async (formData) => {
    try {
      console.log(formData);
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/delivery-charge`,
        formData,
      );

      if (data?.status === "OK") {
        toast.success(" Delivery charge add Successfully");
        onClose();
        setRefresh((prev) => !prev);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateDeliveryCharge = async (formData) => {
    try {
      console.log(formData);
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/delivery-charge/${selectedItem?.id}`,
        formData,
      );

      if (data?.status === "OK") {
        toast.success(" Delivery charge update Successfully");
        onClose();
        setRefresh((prev) => !prev);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const deleteDeliveryCharge = async () => {
    try {
      const data = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/delivery-charge/${selectedItem?.id}`,
      );

      if (data?.status === 204) {
        toast.success(" Delivery Charge remove Successfully");
        onClose();
        setRefresh((prev) => !prev);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <CardLayout
      title={" Delivery Charge"}
      buttonTitle={"Add Delivery Charge"}
      addButtonHandler={() => setModalOpen("create")}
    >
      <ReactTable data={deliveryChargeData} columns={columns} />

      {/*====================== Add New  Delivery charge =======================*/}

      {modalOpen === "create" && (
        <DeliveryChargeForm
          isOpen={modalOpen === "create"}
          onClose={onClose}
          apiHandler={addNewDeliveryCharge}
        />
      )}

      {modalOpen === "update" && (
        <DeliveryChargeForm
          isOpen={modalOpen === "update"}
          onClose={onClose}
          apiHandler={updateDeliveryCharge}
          defaultData={selectedItem}
        />
      )}

      {modalOpen === "delete" && (
        <DeleteModal
          isOpen={modalOpen === "delete"}
          onClose={onClose}
          handleDelete={deleteDeliveryCharge}
        />
      )}
    </CardLayout>
  );
};

export default DeliveryCharge;
