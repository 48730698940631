const durationOptions = [
    {title: '1 Day', value: 1},
    {title: '3 Days', value: 3},
    {title: '1 Week', value: 7},
    {title: '2 Weeks', value: 14},
    {title: '1 Month', value: 30},
    {title: '3 Months', value: 90},
    {title: '6 Months', value: 180},
    {title: '1 Year', value: 365},
    {title: '2 Years', value: 730},
];

export default durationOptions;