import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import Schedule from "./Schedule";
import Button from "react-bootstrap/Button";
import UploadReport from "./UploadReports";
import formatDateTime from "../../utils/DateTimeFormate";
import { UserContext } from "../../Context/UserContextAPI";
import { Form } from "react-bootstrap";

export default function DiagoRequestList() {
  const [getAllDiagnoData, setGetAllDiagnoData] = useState([]);
  const [filteredDiagnoData, setFilteredDiagnoData] = useState([]);
  const [showSchedule, setShowSchedule] = useState(false);
  const [singleItem, setSingleItem] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [showUploadReport, setShowUploadReport] = useState(false);
  const { accessPerm } = useContext(UserContext);

  useEffect(() => {
    (async function () {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/services/request/test/admin/getAll`,
        );
        console.log("data", data.data);
        setGetAllDiagnoData(data.data);
        setFilteredDiagnoData(data.data);
      } catch (error) {
        console.log("error while get all req list", error);
      }
    })();
  }, [refresh]);

  const columns = [
    {
      key: "req_no",
      text: "Request No:",
      align: "center",
      sortable: true,
    },
    {
      key: "req_type",
      text: "Request Type",
      // className: 'action',
      // width: 100,
      align: "center",
      sortable: false,
      cell: (data) => {
        return (
          <span>
            {data.req_type === 1
              ? "General"
              : data.req_type === 2
              ? "Selective"
              : "unknown"}
          </span>
        );
      },
    },
    {
      key: "select_type",
      text: "Select Type",
      // className: 'action',
      // width: 100,
      align: "center",
      sortable: false,
      cell: (data) => {
        return (
          <span>
            {data.req_type === 2
              ? "Prescription Pictures"
              : data.req_type === 1
              ? "Investigation"
              : "unknown"}
          </span>
        );
      },
    },
    {
      key: "reg_no",
      text: "Registration No:",
      // className: 'action',
      // width: 100,
      align: "center",
      sortable: false,
      cell: (data) => {
        return <span>{data?.test_requester?.registration_no}</span>;
      },
    },
    {
      key: "req_by",
      text: "Request By",
      // className: 'action',
      // width: 100,
      align: "center",
      sortable: false,
      cell: (data) => {
        return <span>{data?.test_requester?.f_name}</span>;
      },
    },
    {
      key: "mobile",
      text: "Mobile",
      // className: 'action',
      // width: 100,
      align: "center",
      sortable: false,
      cell: (data) => {
        return <span>{data?.test_requester?.mobile}</span>;
      },
    },
    {
      key: "service_center_id",
      text: "Service Center",
      // className: 'action',
      // width: 100,
      align: "center",
      sortable: false,
      cell: (data) => {
        return (
          <span>
            {data?.service_center_name?.f_name}
            {data?.service_center_name?.l_name}
          </span>
        );
      },
    },
    {
      key: "createdAt",
      text: "Request Date",
      // className: 'action',
      // width: 100,
      align: "center",
      sortable: true,
      cell: (data) => {
        return <span>{formatDateTime(data.createdAt)}</span>;
      },
    },
    {
      key: "schedule_date",
      text: "Schedule Date",
      // className: 'action',
      // width: 100,
      align: "center",
      sortable: true,
      cell: (data) => {
        return (
          <span>
            {data.schedule_date && formatDateTime(data.schedule_date)}
          </span>
        );
      },
    },
    {
      key: "status",
      text: "status",
      // className: 'action',
      // width: 100,
      align: "center",
      sortable: false,
      cell: (data) => {
        return (
          <span>
            {data.status === 0
              ? "Requested"
              : data.status === 1
              ? "Diagnostic center respond"
              : data.status === 2
              ? "User Confirm"
              : data.status === 3
              ? "User Canceled"
              : data.status === 4
              ? "Diagnostic Marked Complete"
              : data.status === 5
              ? "Schedule"
              : data.status === 6
              ? "Report Uploaded"
              : "unknown"}
          </span>
        );
      },
    },
    {
      key: "action",
      text: "Action",
      // className: 'action',
      width: 120,
      align: "center",
      sortable: false,
      cell: (data) => {
        return (
          <>
            {accessPerm(39, 7) && data.status === 2 && (
              <button
                onClick={() => {
                  setShowSchedule(true);
                  setSingleItem(data);
                }}
                className="btn btn-primary btn-sm"
              >
                Set Schedule
              </button>
            )}
            {accessPerm(39, 8) && data.status === 5 && (
              <Button
                onClick={() => {
                  setShowUploadReport(true);
                  setSingleItem(data);
                }}
                variant="success"
              >
                Upload Report
              </Button>
            )}
          </>
        );
      },
    },
  ];

  //configuration
  const config = {
    page_size: 10,
    show_filter: false,
    show_length_menu: true,
    show_pagination: true,
    pagination: "advance",
    length_menu: [10, 50, 100],
    button: {
      extra: true,
    },
  };

  // search component

  const extraButtons = [
    {
      className: "bg-transparent border-0",

      title: "Search",
      children: [
        <Form.Group controlId="formBasicText">
          <Form.Control
            type={"search"}
            placeholder={"Search"}
            // value={value}
            onChange={(e) => handlerFilter(e.target.value)}
          />
        </Form.Group>,
      ],
      onClick: (event) => {
        // console.log(event);
      },
    },
  ];

  // search/filter functions

  const handlerFilter = (value) => {
    setFilteredDiagnoData(
      getAllDiagnoData.filter((item) => {
        return (
          item?.req_no?.toString()?.includes(value.toLowerCase()) ||
          item?.test_requester?.f_name
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          item?.service_center_name?.f_name
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          item?.test_requester?.registration_no
            ?.toString()
            ?.includes(value.toLowerCase()) ||
          item?.test_requester?.mobile
            ?.toString()
            ?.includes(value.toLowerCase())
        );
      }),
    );
  };

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="border p-3 rounded">
            <div className="card-box">
              <h6 className="mb-0 text-uppercase">
                All Diagnosis Request List
              </h6>
              <div className="col">{/* Modal */}</div>
            </div>

            <hr />

            <ReactDatatable
              config={config}
              records={filteredDiagnoData}
              columns={columns}
              extraButtons={extraButtons}
            />
          </div>
        </div>
      </div>
      {showSchedule && (
        <Schedule
          item={singleItem}
          showSchedule={showSchedule}
          setShowSchedule={setShowSchedule}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )}
      {showUploadReport && (
        <UploadReport
          uploadReportModal={showUploadReport}
          setUploadREportModal={setShowUploadReport}
          item={singleItem}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )}
    </div>
  );
}
