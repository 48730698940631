import axios from "axios";
import React, { useEffect, useState } from "react";
import { format, startOfMonth } from "date-fns";
import "./ProfitLossStatement.scss";

const ProfitLossStatement = () => {
  const [transactionFrom, setTransactionFrom] = useState(
    format(startOfMonth(new Date()), "yyyy-MM-dd")
  );
  const [transactionTo, setTransactionTo] = useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const [profitLoss, setProfitLoss] = useState();

  const handlerOnSearch = (e) => {
    e.preventDefault();

    const form = e.target;
    const fromDate = form.from_date.value;
    const toDate = form.to_date.value;

    setTransactionFrom(fromDate);
    setTransactionTo(toDate);

    const filterData = {
      fromDate,
      toDate,
    };

    methodFilterPatientsTrailList(filterData);
  };

  const methodFilterPatientsTrailList = async (filterData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/voucher/search/profit-loss`,
        filterData
      );
      console.log("found", response?.data);
      setProfitLoss(response?.data);
      const sortedDates = response?.data.map(
        (item) => new Date(item?.createdAt)
      );
      sortedDates.sort((a, b) => a - b);
      // setTrailInfo(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const totalAmountForRevenue = profitLoss
    ?.filter((item) => item?.classification_id === 8)
    .reduce(
      (accumulator, item) => accumulator + parseFloat(item?.amount || 0),
      0
    );
  const totalAmountForNonOperating = profitLoss
    ?.filter((item) => item?.classification_id === 6)
    .reduce(
      (accumulator, item) => accumulator + parseFloat(item?.amount || 0),
      0
    );
  const totalAmountForOtherOperating = profitLoss
    ?.filter((item) => item?.classification_id === 7)
    .reduce(
      (accumulator, item) => accumulator + parseFloat(item?.amount || 0),
      0
    );
  const totalAmountForGoodsSoldClassification = profitLoss
    ?.filter((item) => item?.classification_id === 9)
    .reduce(
      (accumulator, item) => accumulator + parseFloat(item?.amount || 0),
      0
    );
  const totalAmountForOfficeAdmin = profitLoss
    ?.filter((item) => item?.classification_id === 10)
    .reduce(
      (accumulator, item) => accumulator + parseFloat(item?.amount || 0),
      0
    );
  const totalAmountForFinancialExpense = profitLoss
    ?.filter((item) => item?.classification_id === 11)
    .reduce(
      (accumulator, item) => accumulator + parseFloat(item?.amount || 0),
      0
    );

  const grossIncome =
    totalAmountForRevenue +
    totalAmountForOtherOperating +
    totalAmountForNonOperating;
  const grossExpense =
    totalAmountForGoodsSoldClassification +
    totalAmountForOfficeAdmin +
    totalAmountForFinancialExpense;

  const netTotal = grossIncome - grossExpense;

  const hasRevenueClassification = profitLoss?.some(
    (item) => item?.classification_id === 8
  );
  const hasNonOperatingClassification = profitLoss?.some(
    (item) => item?.classification_id === 6
  );
  const hasOtherOperatingClassification = profitLoss?.some(
    (item) => item?.classification_id === 7
  );
  const hasCostOfGoodsSoldClassification = profitLoss?.some(
    (item) => item?.classification_id === 9
  );
  const hasOfficeAdminClassification = profitLoss?.some(
    (item) => item?.classification_id === 10
  );
  const hasFinancialExpensesClassification = profitLoss?.some(
    (item) => item?.classification_id === 11
  );

  console.log(hasRevenueClassification);
  useEffect(() => {
    const defaultSearch = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/voucher/search/profit-loss`,
          { fromDate: transactionFrom, toDate: transactionTo }
        );
        console.log("found", response?.data);
        setProfitLoss(response?.data);
        const sortedDates = response?.data.map(
          (item) => new Date(item?.createdAt)
        );
        sortedDates.sort((a, b) => a - b);
      } catch (error) {
        console.log(error);
      }
    };
    defaultSearch();
  }, []);

  return (
    <div className="profit-loss">
      <div className="d-flex flex-column w-100 align-items-stretch">
        <div className="profit-loss-search">
          <div className="card shadow-none">
            <div className="border p-2 rounded">
              <form onSubmit={(e) => handlerOnSearch(e)}>
                <div className="d-flex gap-4 pb-2">
                  <div className="w-25">
                    <label className="col-form-label fw-bold">From Date</label>
                    <div className="">
                      <input
                        type="date"
                        data-date-format="YYYY MM DD"
                        name="from_date"
                        className="form-control"
                        placeholder="Date Picker..."
                        defaultValue={transactionFrom}
                      />
                    </div>
                  </div>
                  <div className="w-25">
                    <label className="col-form-label fw-bold">To Date</label>
                    <div className="">
                      <input
                        type="date"
                        data-date-format="YYYY MM DD"
                        name="to_date"
                        className="form-control"
                        placeholder="Date Picker..."
                        defaultValue={transactionTo}
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-end">
                    <button
                      type="submit"
                      className="btn btn-success border border-3 pt-1"
                      style={{
                        fontSize: "15px", // Increase the font size of header titles
                        backgroundColor: "#0d6efd",
                        color: "white",
                        border: "none",
                      }}
                    >
                      Generate Profit Loss Statement
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="w-25 d-flex flex-column align-items-center w-100">
          <h5>EUREKA DIAGNOSTIC & MEDICAL CENTER</h5>
          <p>Statement of Profit & Loss</p>
          <p className="m-0 p-0">
            From {transactionFrom} To {transactionTo}
          </p>
          <p className="p-0">(All values in BDT)</p>
        </div>
        <div className="container mt-2 profit-loss-table">
          <table className="w-75 mx-auto table table-bordered">
            <thead>
              <tr>
                <th className="text-center">Particulars</th>
                <th className="text-center">Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong className="font-weight">INCOME</strong>
                </td>
                <td></td>
              </tr>
              {hasRevenueClassification && totalAmountForRevenue !== 0 && (
                <tr>
                  <td className="font-weight">REVENUE</td>
                  <td
                    style={{ textAlign: "end", fontStyle: "italic" }}
                    className="font-weight"
                  >
                    {totalAmountForRevenue ? totalAmountForRevenue : ""}
                  </td>
                </tr>
              )}

              {profitLoss?.map(
                (item) =>
                  item.amount !== 0 &&
                  item.classification_id === 8 && (
                    <tr key={item?.head_group_id}>
                      <td className="font-weight">{item.head_group_name}</td>
                      <td style={{ textAlign: "end" }} className="font-weight">
                        {item.amount}
                      </td>
                    </tr>
                  )
              )}

              {hasNonOperatingClassification &&
                totalAmountForNonOperating !== 0 && (
                  <tr>
                    <td className="font-weight">NON-OPERATING INCOME</td>
                    <td
                      style={{ textAlign: "end", fontStyle: "italic" }}
                      className="font-weight"
                    >
                      {totalAmountForNonOperating}
                    </td>
                  </tr>
                )}

              {profitLoss?.map(
                (item) =>
                  item.classification_id === 6 &&
                  item.amount !== 0 && (
                    <tr key={item?.head_group_id}>
                      <td className="font-weight">{item.head_group_name}</td>
                      <td style={{ textAlign: "end" }} className="font-weight">
                        {item.amount}
                      </td>
                    </tr>
                  )
              )}

              {hasOtherOperatingClassification &&
                totalAmountForOtherOperating !== 0 && (
                  <tr>
                    <td className="font-weight">OTHER OPERATING INCOME</td>
                    <td
                      style={{ textAlign: "end", fontStyle: "italic" }}
                      className="font-weight"
                    >
                      {totalAmountForOtherOperating}
                    </td>
                  </tr>
                )}

              {profitLoss?.map(
                (item) =>
                  item.classification_id === 7 &&
                  item.amount !== 0 && (
                    <tr key={item?.head_group_id}>
                      <td className=" font-weight">{item.head_group_name}</td>
                      <td style={{ textAlign: "end" }} className="font-weight">
                        {item.amount}
                      </td>
                    </tr>
                  )
              )}

              <tr className="">
                <td>
                  <strong>Gross Income</strong>
                </td>
                <td style={{ textAlign: "end" }}>{grossIncome}</td>
              </tr>
              <tr>
                <td>
                  <strong className="font-weight">EXPENDITURE</strong>
                </td>
                <td></td>
              </tr>
              {hasCostOfGoodsSoldClassification &&
                totalAmountForGoodsSoldClassification !== 0 && (
                  <tr>
                    <td className="font-weight">COST OF GOODS SOLD</td>
                    <td
                      style={{ textAlign: "end", fontStyle: "italic" }}
                      className="font-weight"
                    >
                      {totalAmountForGoodsSoldClassification
                        ? totalAmountForGoodsSoldClassification
                        : ""}
                    </td>
                  </tr>
                )}

              {profitLoss?.map(
                (item) =>
                  item.classification_id === 9 &&
                  item.amount !== 0 && (
                    <tr key={item?.head_group_id}>
                      <td className=" font-weight">{item.head_group_name}</td>
                      <td className="font-weight">{item.amount}</td>
                    </tr>
                  )
              )}

              {hasOfficeAdminClassification &&
                totalAmountForOfficeAdmin !== 0 && (
                  <tr>
                    <td className="font-weight">
                      OFFICE, ADMINISTRATIVE AND SELLING EXPENSES
                    </td>
                    <td
                      className="font-weight"
                      style={{ textAlign: "end", fontStyle: "italic" }}
                    >
                      {totalAmountForOfficeAdmin
                        ? totalAmountForOfficeAdmin
                        : ""}
                    </td>
                  </tr>
                )}

              {profitLoss?.map(
                (item) =>
                  item.classification_id === 10 &&
                  item.amount !== 0 && (
                    <tr key={item?.head_group_id}>
                      <td className=" font-weight">{item.head_group_name}</td>
                      <td className="font-weight" style={{ textAlign: "end" }}>
                        {item.amount}
                      </td>
                    </tr>
                  )
              )}

              {hasFinancialExpensesClassification &&
                totalAmountForFinancialExpense !== 0 && (
                  <tr>
                    <td className="font-weight">FINANCIAL EXPENSES </td>
                    <td
                      className="font-weight"
                      style={{ textAlign: "end", fontStyle: "italic" }}
                    >
                      {totalAmountForFinancialExpense
                        ? totalAmountForFinancialExpense
                        : ""}
                    </td>
                  </tr>
                )}

              {profitLoss?.map(
                (item) =>
                  item.classification_id === 11 &&
                  item.amount !== 0 && (
                    <tr key={item?.head_group_id}>
                      <td className=" font-weight">{item.head_group_name}</td>
                      <td className="font-weight" style={{ textAlign: "end" }}>
                        {item.amount}
                      </td>
                    </tr>
                  )
              )}

              <tr className="">
                <td>
                  <strong>Gross Expense</strong>
                </td>
                <td style={{ textAlign: "end" }}>{grossExpense}</td>
              </tr>

              <tr>
                <td>
                  <strong>Net Profit / Loss</strong>
                </td>
                <td style={{ textAlign: "end" }}>{netTotal}</td>
              </tr>
            </tbody>
          </table>
          <div className="d-flex justify-content-center mt-5 unfit-button p-2">
            <button
              className="btn btn-primary btn-lg w-25"
              onClick={() => window.print()}
            >
              Print
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfitLossStatement;
