import axios from "axios";
import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import toast from "react-hot-toast";
import {timeToDateFormate} from '../../utils/timeAndDateFunction'

export default function EditAdvertisement({
                                              isEditModalOpen,
                                              setEditModalOpen,
                                              refresh,
                                              setRefresh,
                                              item,
                                              adSection
                                          }) {
    const [isChecked, setIsChecked] = useState(item ? item.status === 1 ? true : false : false);
    const [startDate, setStartDate] = useState(item.start_date ? new Date(item.start_date) : new Date());
    const [endDate, setEndDate] = useState(
        item.end_date ? new Date(item.end_date) : new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
    );
    const [startTime, setStartTime] = useState(item.start_time ? timeToDateFormate(item.start_time) : new Date());
    const [endTime, setEndTime] = useState(item.end_time ? timeToDateFormate(item.end_time) : new Date(new Date().getTime() + 24 * 60 * 60 * 1000))
    const [mediaType, setMediaType] = useState(!!item?.media_type ? item?.media_type : 1);

    const [file, setFile] = useState("");
// console.log(item)


    // submit handler for post advertise
    const handleEdit = async (e) => {
        e.preventDefault();
        try {
            if (file && file.size > 2 * 1024 * 1024) { // shows in bits
                toast.error('File size exceeds the limit of 2MB');
                return;
            }
            let uploadedFile;
            if (file) {  // checking if user change the file or not . if user update the file then images will upload;
                const formData = new FormData();
                formData.append("file", file);
                uploadedFile = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/advertisement/upload/images`, formData) // upload images api
            }

            console.log("uploadedImages", uploadedFile);

            const form = e.target;
            const obj = {
                name: form.name?.value,
                ad_section: form.adSection?.value,
                media_type: mediaType,
                file: file ? uploadedFile.data?.filename : item.media, // if file changes then changed file will upload , if not prev file will upload
                link: form.url?.value,
                start_date: startDate?.toISOString().slice(0, 10),
                start_time: startTime?.toTimeString().slice(0, 8),
                end_date: endDate?.toISOString().slice(0, 10),
                end_time: endTime?.toTimeString().slice(0, 8),
                info: form.info?.value,
                status: isChecked === true ? 1 : 0,
            };
            // console.log(obj)
            const res = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/advertisement/${item?.id}`, obj) // upload advertise data api
            if (res.data) {
                toast.success('Advertise Updated successfully');
                setEditModalOpen(false); // close the modal
                setRefresh(!refresh); // re-fetch the full data
            }


        } catch (error) {
            console.log(error)
        }
    };


    const mediaTypeData = [
        {
            id: 1,
            title: "Image"
        },
        {
            id: 2,
            title: "Video"
        }
    ]

    return (
        <Modal
            show={isEditModalOpen}
            onHide={() => {
                setEditModalOpen(false)
                item = null
            }}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title> {item ? "Edit" : "Create New"} Advertisement</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleEdit}>
                    <div className="modal-body">
                        <div className="row mb-3 d-flex align-items-center">
                            <label className="col-sm-3 col-form-label">
                                Name <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9">
                                <input
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    defaultValue={item ? item?.name : ""}
                                    required
                                />
                            </div>
                        </div>

                        <div className="row mb-3 d-flex align-items-center">
                            <label className="col-sm-3 col-form-label">
                                Ad section <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9">
                                <select
                                    name="adSection"
                                    id=""
                                    className="single-select form-select"
                                    defaultValue={item && item?.ad_section_id}
                                >
                                    {/* <option value="">new new</option> */}
                                    {adSection.map((ad) => (
                                        <option key={ad?.id} value={ad?.id}
                                                selected={item && item?.ad_section_id === ad?.id}>
                                            {ad?.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="row mb-3 d-flex align-items-center">
                            <label className="col-sm-3 col-form-label">
                                Media Type <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9">
                                <select
                                    onChange={(e) => setMediaType(parseInt(e.target.value))}
                                    name="mediaType"
                                    id=""
                                    className="single-select form-select"
                                    defaultValue={item && item?.media_type}
                                >
                                    {
                                        mediaTypeData.map(media => (
                                            <option key={media.id} value={media.id}
                                                    selected={item && item?.media_type === media.id}>{media.title}</option>
                                        ))
                                    }

                                </select>
                            </div>
                        </div>

                        <div className="row mb-3 d-flex align-items-center">
                            <label className="col-sm-3 col-form-label">
                                Upload Media <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9">
                                <input
                                    type="file"
                                    name="file"
                                    accept={mediaType === 1 ? "image/*" : "video/*"}
                                    onChange={(e) => setFile(e.target.files[0])}
                                />
                            </div>
                        </div>

                        <div className="row mb-3 d-flex align-items-center">
                            <label className="col-sm-3 col-form-label">
                                Link
                            </label>
                            <div className="col-sm-9">
                                <input
                                    type="url"
                                    name="url"
                                    className="form-control"
                                    defaultValue={item ? item?.link : ""}
                                    // required
                                />
                            </div>
                        </div>

                        <div className=" row mb-3 d-flex justify-content-between">
                            <div className="col-6 mb-3 d-flex justify-content-between">
                                <label className=" ">Start date<span className="text-danger">*</span> </label>
                                <div className=" ml-3">
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        //   showTimeSelect
                                        //   filterTime={filterPassedTime}
                                        dateFormat="MMMM d, yyyy"
                                    />
                                </div>
                            </div>

                            <div className="col-6 mb-3 d-flex justify-content-end" style={{gap: "1rem"}}>
                                <label className=" ">End date<span className="text-danger">*</span> </label>
                                <div className=" ml-3">
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        //   showTimeSelect
                                        //   filterTime={filterPassedTime}
                                        dateFormat="MMMM d, yyyy"
                                    />
                                </div>
                            </div>

                        </div>

                        <div className=" row mb-3 d-flex justify-content-between">

                            <div className="col-6 d-flex mb-3 justify-content-between ">
                                <label className="mr-5">Start Time<span className="text-danger">*</span> </label>
                                <div className="">
                                    <DatePicker
                                        selected={startTime}
                                        onChange={(time) => setStartTime(time)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                    />
                                </div>
                            </div>

                            <div className="col-6 d-flex mb-3 justify-content-end" style={{gap: "1rem"}}>
                                <label className="mr-5">End Time<span className="text-danger">*</span> </label>
                                <div className="">
                                    <DatePicker
                                        selected={endTime}
                                        onChange={(time) => setEndTime(time)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label className="col-sm-3 col-form-label">Info</label>
                            <div className="col-sm-9">
                <textarea
                    name="info"
                    className="form-control w-100"
                    rows="3"
                    maxLength="200"
                    // required={true}
                    defaultValue={item ? item?.info : ""}
                ></textarea>
                            </div>
                        </div>

                        <div className="row mb-3 d-flex align-items-center justify-content-end">
                            <div className="col-sm-9">
                                <div className="col-sm-12">
                                    <div className=" d-flex align-items-center  justify-content-end">
                                        <input
                                            className="form-check-input mt-0 me-2"
                                            type="checkbox"
                                            checked={isChecked}
                                            name="status"
                                            value={isChecked}
                                            onChange={() => setIsChecked(!isChecked)}
                                            id="flexCheckChecked"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="flexCheckChecked"
                                        >
                                            Active
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => {
                                setEditModalOpen(false)
                                item = null
                            }}
                        >
                            Close
                        </button>
                        <button type="submit" className="btn btn-primary">
                            Save changes
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}
