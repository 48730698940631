import ReactDatatable from "@ashvin27/react-datatable";
import axios from "axios";
import React, { useEffect, useState } from "react";
// import { FaRegFilePdf } from 'react-icons/fa';
import Select from "react-select";
import "./VoucherList.scss";
import { format } from "date-fns";

const VoucherList = () => {
  const [voucherList, setVoucherList] = useState([]);
  const [transectionFrom, setTransectionFrom] = useState([]);
  const [transectionTo, setTransectionTo] = useState([]);
  const [headGroup, setHeadGroup] = useState([]);
  const [accountHead, setAccountHead] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const handlerOnSearch = (e) => {
    e.preventDefault();

    const form = e.target;
    const fromDate = form.from_date.value;
    const toDate = form.to_date.value;
    const head_group_id = form.head_group_id.value;
    const account_head_id = form.account_head_id.value;

    const filterData = {
      fromDate,
      toDate,
      head_group_id: head_group_id.trim(),
      account_head_id: account_head_id.trim(),
    };
    console.log(filterData);

    methodFilterPatientsVoucherList(filterData);
  };

  const methodFilterPatientsVoucherList = async (filterData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/voucher/search`,
        filterData
      );
      console.log(response?.data);
      setVoucherList(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      textAlign: "left",
    }),
    menu: (provided) => ({
      ...provided,
      textAlign: "left",
    }),
  };

  useEffect(() => {
    const fetchAPI = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/voucher/all`
      );
      const accountHead = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/account-head`
      );
      const data = response?.data?.data;
      const accHeadData = accountHead?.data?.data;
      setAccountHead(accHeadData);
      const transectionFromData = data?.map((item) => {
        return `${item?.from_account_head?.code.trim()} - ${item?.from_account_head?.name.trim()}`;
      });

      const uniqueTransectionFromData = [...new Set(transectionFromData)];

      const transectionToData = data?.map((item) => {
        return item?.to_account_head?.name.trim();
      });

      const uniqueTransectionToData = [...new Set(transectionToData)];

      setTransectionFrom(uniqueTransectionFromData);
      setTransectionTo(uniqueTransectionToData);

      setVoucherList(data);
      setRefresh(refresh);
    };

    const fetchHeadGroup = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/head-group/all`
      );
      const data = res?.data?.data;
      setHeadGroup(data);
    };
    fetchHeadGroup();
    fetchAPI();
  }, [refresh]);

  const columns = [
    {
      key: "date",
      text: "Date",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <>{record.createdAt.split("T")[0]}</>;
      },
    },
    {
      key: "from_head_group",
      text: "Head Group",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <>{record?.head_group?.name}</>;
      },
    },
    {
      key: "transection_from",
      text: "Account Head",
      className: "",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            {record?.account_head?.code} - {record?.account_head?.name}
          </>
        );
      },
    },
    {
      key: "transection_for",
      text: "Transection For",
      className: "",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <>{record?.transection_for}</>;
      },
    },
    {
      key: "debit",
      text: "Debit",
      className: "Debit",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <>{record?.amount <= 0 ? 0 : record?.amount}</>;
      },
    },
    {
      key: "credit",
      text: "Credit",
      className: "Credit",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <>{record?.amount < 0 ? record?.amount * -1 : 0}</>;
      },
    },
    {
      key: "remarks",
      text: "Remarks",
      className: "Remarks",
      sortable: true,
      cell: (record) => {
        return <>{record?.info}</>;
      },
    },
  ];

  const config = {
    page_size: 10,
    show_filter: true,
    show_length_menu: true,
    show_pagination: true,
    pagination: "advance",
    length_menu: [10, 50, 100],
    button: {
      excel: true,
      print: true,
      extra: true,
    },
  };

  const extraButtons = [];

  return (
    <>
      <>
        <div className="card">
          <div className="card-body">
            <div className="border p-3 rounded">
              <div className="card-box">
                <h6 className="mb-0 text-uppercase">Voucher List</h6>
              </div>

              <hr />
              <div className="shadow-none">
                <div className="border p-1 rounded">
                  <div className="">
                    {/* <h6 className='mb-0 text-uppercase'>Patients Information</h6>
                <div className='col'>
                  
                </div> */}
                    <form onSubmit={(e) => handlerOnSearch(e)}>
                      <div className="d-flex justify-content-evenly gap-3">
                        <div className="w-25">
                          <label className="col-form-label">
                            From <span className="text-danger">*</span>
                          </label>
                          <div className="">
                            <input
                              type="date"
                              data-date-format="YYYY MM DD"
                              name="from_date"
                              className="form-control"
                              placeholder="Date Picker..."
                              required
                              defaultValue={format(new Date(), "yyyy-MM-dd")}
                            />
                          </div>
                        </div>
                        <div className="w-25">
                          <label className=" col-form-label">
                            To <span className="text-danger">*</span>
                          </label>
                          <div className="">
                            <input
                              type="date"
                              data-date-format="YYYY MM DD"
                              name="to_date"
                              className="form-control"
                              placeholder="Date Picker..."
                              required
                              defaultValue={format(new Date(), "yyyy-MM-dd")}
                            />
                          </div>
                        </div>
                        <div className="w-25">
                          <label className=" col-form-label">Head Group</label>
                          <div className="">
                            <Select
                              className="patient-form-select"
                              name="head_group_id"
                              options={[
                                { value: "", label: "Choose One..." },
                                ...headGroup.map((from_headGroup, i) => ({
                                  value: from_headGroup.id,
                                  label: from_headGroup.name,
                                })),
                              ]}
                              placeholder="Choose One..."
                              isSearchable={true}
                              required
                              styles={customStyles}
                            />
                          </div>
                        </div>
                        <div className="w-25">
                          <label className="col-form-label">Account Head</label>
                          <div className="">
                            <Select
                              className="patient-form-select"
                              name="account_head_id"
                              options={[
                                { value: "", label: "Choose One..." }, // Default option
                                ...accountHead.map((accHead, i) => ({
                                  value: accHead.id,
                                  label: accHead.name,
                                })),
                              ]}
                              placeholder="Choose One..."
                              isSearchable={true}
                              required
                              styles={customStyles}
                            />
                          </div>
                        </div>

                        <div className="d-flex justify-content-end align-items-end patient-info-search">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{ width: "6rem" }}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div
                className="d-flex justify-content-end mt-3"
                style={{ paddingRight: "15.6rem" }}
              >
                <label htmlFor="transection-for" className="voucher-label mb-2">
                  Transection For
                </label>
              </div>

              <ReactDatatable
                config={config}
                records={voucherList}
                columns={columns}
                extraButtons={extraButtons}
              />
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default VoucherList;
