import React from 'react';
import Modal from "react-bootstrap/Modal";
import CategoryForm from "./CategoryForm";
import axios from "axios";
import toast from "react-hot-toast";

const CreateKnowledgeBaseCat = ({onClose, isOpen, refresh}) => {

    const createKnowledgeBaseCat = async (formData) => {
        try {
            console.log(formData)

            const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/knowledge-base-cat`, formData)

            console.log(res)
            if (res.data?.status === "OK") {
                toast.success("Knowledge base category created successfully");
                onClose();
                refresh();
            }

        } catch (err) {
            console.log(err)
        }
    }

    return (
        <Modal show={isOpen} onHide={onClose}>
            <Modal.Header closeButton>
                <h5>Create Knowledge Base Category</h5>
            </Modal.Header>
            <Modal.Body>
                <CategoryForm apiHandler={createKnowledgeBaseCat}/>
            </Modal.Body>

        </Modal>
    );
};

export default CreateKnowledgeBaseCat;