import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import KnowledgeBaseForm from "./KnowledgeBaseForm";
import axios from "axios";
import toast from "react-hot-toast";
import CardLayout from "../../../components/Layout/CardLayout";
import { useNavigate } from "react-router-dom";
import useFetchData from "../../../hooks/useFetchData";

const CreateKnowledgeBase = () => {
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const createKnowledgeBase = async (formData) => {
    try {
      setSubmitting(true);
      // console.log(formData)
      // return
      const data = new FormData();
      data.append("category_id", formData.category_id);
      data.append("title", formData.title);
      data.append("info", formData.info);
      data.append("short_info", formData.short_info);
      data.append("order_by", formData.order_by || 0);
      data.append("status", formData.status);
      data.append("image", formData.image);
      data.append("video", formData.video);
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/knowledgeBase`,
        data
      );

      console.log(res);
      if (res.data?.status === "OK") {
        toast.success("Knowledge base category created successfully");
        navigate("/dashboard/knowledge-base/list");
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data || "Something went wrong");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <CardLayout title="Create Knowledge Base">
      <KnowledgeBaseForm
        apiHandler={createKnowledgeBase}
        submitting={submitting}
      />
    </CardLayout>
  );
};

export default CreateKnowledgeBase;
