import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

export default function BloodRequestList() {
    const [allBloodData, setAllBloodData] = useState([]);
    const [filteredBloodData, setFilteredBloodData] = useState([]);

    useEffect(() => {
        (async function() {
            try {
                const { data } = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/services/request/blood/all-blood-request`,
                );
                // console.log("blood", data.data);
                // Object.groupBy(data.data, (groupData)=>{console.log(groupData)})
                setAllBloodData(data.data);
                setFilteredBloodData(data.data);
            } catch (error) {
                console.log('error while fetch all blood data', error);
            }
        })();
    }, []);

    // make all blood request to unique with req_id

    // column

    const columns = [
        {
            key: 'req_no',
            text: 'Request No:',
            align: 'center',
            sortable: true,
        },
        // {
        //   key: 'Request Type',
        //   text: 'Request Type',
        //   className: 'info',
        //   align: 'center',
        //   sortable: true,
        // },
        {
            key: 'blood_group_label',
            text: 'Blood Group',
            // className: 'status',
            sortable: true,
        },
        {
            key: 'total_bags',
            text: 'Total Bags',
            align: 'center',
            // width:"5%",
            sortable: true,
            cell: (data) => {
                return (
                    <span>
            {data?.total_bags === 1
                ? `${data?.count} Bag`
                : `${data?.total_bags} Bags`}{' '}
          </span>
                );
                // {data?.count === 1 ? `${data?.count} Bag` : `${data?.count} Bags`}
            },
        },
        {
            key: 'reg_no',
            text: 'Registration No:',
            // className: 'action',
            // width: 100,
            align: 'center',
            sortable: false,
            cell: (data) => {
                return <span>{data?.requester?.registration_no}</span>;
            },
        },
        {
            key: 'req_by',
            text: 'Request By',
            // className: 'action',
            // width: 100,
            align: 'center',
            sortable: false,
            cell: (data) => {
                return <span>{data?.requester?.f_name}</span>;
            },
        },
        {
            key: 'mobile',
            text: 'Mobile',
            // className: 'action',
            // width: 100,
            align: 'center',
            sortable: false,
            cell: (data) => {
                return <span>{data?.requester?.mobile}</span>;
            },
        },
        {
            key: 'col_point',
            text: 'Collection Point',
            align: 'center',
            // width:"20%",
            sortable: false,
            cell: (data) => {
                // console.log("cell", data);
                return <span>{data?.col_point?.f_name}</span>;
            },
        },
        {
            key: 'col_addr',
            text: 'Collection Point Address',
            align: 'center',
            //  width:"20%",
            sortable: false,
            cell: (data) => {
                // console.log("cell", data);
                return (
                    <span>
            {data?.col_point &&
                `${data?.col_point?.address_1}, 
            ${data?.col_point?.user_detail?.city.name}, 
            ${data?.col_point?.user_detail?.state.name}, 
            ${data?.col_point?.user_detail?.country.name} `}
          </span>
                );
            },
        },
        {
            key: 'createdAt',
            text: 'Request Date',
            align: 'center',
            sortable: true,
            cell: (data) => {
                const date = new Date(data.createdAt);

                // Formatting the date and time
                const options = {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: 'numeric',
                    minute: '2-digit',
                    hour12: true,
                    // second: "2-digit",
                    // timeZoneName: "short",
                };

                const formattedDate = date.toLocaleString('en-US', options);
                return <span>{formattedDate}</span>;
            },
        },
        {
            key: 'date_time',
            text: 'Needed Date',
            align: 'center',
            sortable: true,
        },
        {
            key: 'time',
            text: 'Time',
            align: 'center',
            sortable: false,
        },
        {
            key: 'status',
            text: 'Status',
            align: 'center',
            sortable: true,
            cell: (data) => {
                return (
                    <span className="fw-semibold ">
            {parseInt(data?.status) === 0
                ? 'Pending'
                : parseInt(data?.status) === 1
                    ? 'Accepted'
                    : parseInt(data?.status) === 2
                        ? 'Collected'
                        : parseInt(data?.status) === 3
                            ? 'Cancel'
                            : 'Unknown'}
          </span>
                );
            },
        },
    ];
    const config = {
        page_size: 10,
        show_filter: false,
        show_length_menu: true,
        show_pagination: true,
        pagination: 'advance',
        length_menu: [10, 50, 100],
        button: {
            extra: true,
        },
    };

    const extraButtons = [
        {
            className: 'bg-transparent border-0',

            title: 'Search',
            children: [
                <Form.Group controlId="formBasicText">
                    <Form.Control
                        type={'search'}
                        placeholder={'Search'}
                        // value={value}
                        onChange={(e) => handlerFilter(e.target.value)}
                    />
                </Form.Group>,
            ],
            onClick: (event) => {
                // console.log(event);
            },
        },
    ];

    // search/filter functions

    const handlerFilter = (value) => {
        setFilteredBloodData(
            allBloodData.filter((item) => {
                return (
                    item.blood_group_label?.toLowerCase().includes(value.toLowerCase()) ||
                    item?.req_no?.toString()?.includes(value.toLowerCase()) ||
                    item?.requester?.f_name.toLowerCase().includes(value.toLowerCase()) ||
                    item?.col_point?.f_name.toLowerCase().includes(value.toLowerCase()) ||
                    item?.requester?.registration_no
                        ?.toString()
                        ?.includes(value.toLowerCase()) ||
                    item?.requester?.mobile?.toString()?.includes(value.toLowerCase())
                );
            }),
        );
    };

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <div className="border p-3 rounded">
                        <div className="card-box">
                            <h6 className="mb-0 text-uppercase">All Blood Request List</h6>
                            <div className="col">{/* Modal */}</div>
                        </div>

                        <hr />

                        <ReactDatatable
                            config={config}
                            records={filteredBloodData}
                            columns={columns}
                            extraButtons={extraButtons}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
