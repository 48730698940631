import React from 'react';
import { Button } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa6';

const DeleteButton = ({ onClick, iconSize }) => (
    <Button size='sm' className='me-3' onClick={onClick} variant='danger'>
        <FaTrash size={iconSize} />
    </Button>
);

export default DeleteButton;
