import React, { useEffect, useState } from 'react';
import axios from 'axios';
import UserTable from './UserTable';
import { getBloodGroupNumber } from '../../../utils/getBloodGroup';

export default function UserListPage() {

    const [users, setUsers] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        const fetchUserAPI = async () => {
            const response = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/users/all-users`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: `Bearer ${localStorage?.getItem('jwtToken')}`,
                    },
                },
            );
            const data = response?.data?.data;
            // console.log(data);
            setUsers(data);
            setFilterData(data);
            setRefresh(refresh);
        };
        fetchUserAPI();
    }, [refresh]);

    const searchHandler = (value) => {
        const trimedValue = value.trim().toLowerCase();
        setFilterData(users.filter(item => (
            item?.f_name?.toLowerCase().includes(trimedValue) ||
            item?.user_detail?.blood_group?.toString()?.toLowerCase()?.includes(getBloodGroupNumber(trimedValue)) ||
            item?.mobile?.toString().includes(trimedValue)
        )));
    };


    return (
        <div>
            <UserTable users={filterData} refresh={refresh} setRefresh={setRefresh} showRole
                       searchHandler={searchHandler} />
        </div>
    );
}
