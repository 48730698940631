import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import BloodDonationList from "../../UserServiceHistoryList/BloodDonationList";
import BloodReceivedList from "../../UserServiceHistoryList/BloodReceivedList";
import InvestigationRequest from "../../UserServiceHistoryList/InvestigationRequest";
import SubscriptionList from "../../UserServiceHistoryList/SubscriptionList";
import MedicineRequest from "../../UserServiceHistoryList/MedicineRequest";
import ProductOrder from "../../UserServiceHistoryList/ProductOrder";
import AmbulanceRequest from "../../UserServiceHistoryList/AmbulanceRequest";
import AppointmentRequest from "../../UserServiceHistoryList/AppointmentRequest";
import TreatmentRequestList from "../../UserServiceHistoryList/TreatmentRequestList";
import PaymentHistory from "../../UserServiceHistoryList/PaymentHistory";
import HealthManagerFiles from "../../UserServiceHistoryList/HealthManagerFiles";
import MinimumPaymentValue from "../DiagnosisDetails/MinimumPaymentValue";
import ChamberList from "../doctorDetailsTab/ChamberList";
import AddChamber from "../doctorDetailsTab/AddChamber";
import ServiceChargeList from "../HospitalDetailsTab/ServiceChargeList";
import AddServiceCharge from "../HospitalDetailsTab/AddServiceCharge";
import useFetch from "../../../hooks/useFetch";
import request from "../../../request/request";
import LoaderLayout from "../../Loader/LoaderLayout";
import UserProfile from "../UserProfile";

const RequestDetailsTab = ({ userDetails, userData }) => {
  // console.log(userData)

  const { result: allRolesData, isLoading } = useFetch(() =>
    request.getAll("roles"),
  );
  // console.log(allRolesData.map((item) => item?.id));

  const [selectedTab, setSelectedTab] = useState("profile");
  const buttonData = [
    {
      id: 12,
      title: "Profile",
      value: "profile",
      role_id: allRolesData.map((item) => item?.id),
    },
    { id: 1, title: "Blood Donation", value: "blood_donation", role_id: [10] },
    { id: 2, title: "Blood Receive", value: "blood_received", role_id: [10] },
    {
      id: 3,
      title: "Investigation",
      value: "investigation",
      role_id: [10, 13],
    },
    { id: 4, title: "Subscription", value: "subscription", role_id: [10] },
    {
      id: 5,
      title: "Medicine Request",
      value: "medicine_request",
      role_id: [10, 16, 12],
    },
    {
      id: 6,
      title: "Product Orders",
      value: "product_orders",
      role_id: [10, 16, 12],
    },
    {
      id: 7,
      title: "Ambulance Request",
      value: "ambulance_request",
      role_id: [10, 14],
    },
    {
      id: 8,
      title: "Appointment Request",
      value: "appointment_request",
      role_id: [10],
    },
    {
      id: 9,
      title: "Treatment Request",
      value: "treatment_request",
      role_id: [10],
      service_cat: ["12"],
    },
    {
      id: 10,
      title: "Payment History",
      value: "payment_history",
      role_id: [10],
    },
    {
      id: 11,
      title: "Health Manager Files",
      value: "health_manager_file",
      role_id: [10],
    },

    {
      id: 13,
      title: "Appointment Request list",
      value: "appointment_request",
      role_id: [11],
    },
    {
      id: 14,
      title: "Chamber List",
      value: "chamber_list",
      role_id: [11],
    },

    {
      id: 16,
      title: "Add Chamber",
      value: "add_chamber",
      role_id: [11],
    },
    {
      id: 17,
      title: "Treatment Request",
      value: "treatment_request",
      role_id: [13],
    },
    {
      id: 18,
      title: "Service Charge List",
      value: "service_charge_list",
      role_id: [13],
    },
    {
      id: 19,
      title: "Add Service Charge",
      value: "service_charge_add",
      role_id: [13],
    },
    {
      id: 11,
      title: "Min Payment Value",
      value: "min_payment_value",
      role_id: [16, 14, 13],
    },
  ];

  return (
    <LoaderLayout isPending={isLoading}>
      <>
        {buttonData.map((button) => {
          // Check if the user's role_id is included in the button's role_id array

          if (button.role_id && !button.role_id.includes(userData?.role_id)) {
            return null;
          }

          if (
            userData?.service_category_id === "13" &&
            button.value === "investigation"
          ) {
            return null;
          }

          if (
            userData?.service_category_id !== "13" &&
            [
              "treatment_request",
              "service_charge_list",
              "service_charge_add",
            ].includes(button.value)
          ) {
            return null;
          }

          return (
            <Button
              className="mt-2 ms-1"
              onClick={() => setSelectedTab(button.value)}
              variant={
                selectedTab === button?.value ? "primary" : "outline-primary"
              }
            >
              {button.title}
            </Button>
          );
        })}
      </>

      <div className="mt-4">
        {selectedTab === "blood_donation" && (
          <BloodDonationList user_id={userData?.id} />
        )}
        {selectedTab === "blood_received" && (
          <BloodReceivedList user_id={userData?.id} />
        )}
        {selectedTab === "investigation" && (
          <InvestigationRequest
            user_id={userData?.id}
            role_id={userData?.role_id}
          />
        )}
        {selectedTab === "subscription" && (
          <SubscriptionList user_id={userData?.id} />
        )}
        {selectedTab === "medicine_request" && (
          <MedicineRequest user_id={userData?.id} role_id={userData?.role_id} />
        )}
        {selectedTab === "product_orders" && (
          <ProductOrder user_id={userData?.id} role_id={userData?.role_id} />
        )}
        {selectedTab === "ambulance_request" && (
          <AmbulanceRequest
            user_id={userData?.id}
            role_id={userData?.role_id}
          />
        )}
        {/* {selectedTab === "appointment_request" && ( */}
        {/*   <AppointmentRequest */}
        {/*     user_id={userData?.id} */}
        {/*     role_id={userData?.role_id} */}
        {/*   /> */}
        {/* )} */}
        {selectedTab === "treatment_request" && (
          <TreatmentRequestList
            user_id={userData?.id}
            role_id={userData?.role_id}
          />
        )}
        {selectedTab === "payment_history" && (
          <PaymentHistory user_id={userData?.id} />
        )}
        {selectedTab === "health_manager_file" && (
          <HealthManagerFiles user_id={userData?.id} />
        )}

        {selectedTab === "min_payment_value" && (
          <MinimumPaymentValue userData={userData} />
        )}

        {/* Doctor tab component  */}

        {selectedTab === "chamber_list" && (
          <ChamberList userDetails={userDetails} />
        )}

        {selectedTab === "add_chamber" && (
          <AddChamber userDetails={userDetails} />
        )}

        {selectedTab === "appointment_request" && (
          <AppointmentRequest
            user_id={userData?.id}
            role_id={userData?.role_id}
          />
        )}

        {/* Hospital component  */}

        {selectedTab === "service_charge_list" && (
          <ServiceChargeList userDetails={userDetails} />
        )}

        {selectedTab === "service_charge_add" && (
          <AddServiceCharge userDetails={userDetails} />
        )}

        {selectedTab === "profile" && <UserProfile userData={userData} />}
      </div>
    </LoaderLayout>
  );
};

export default RequestDetailsTab;
