import { useState } from "react";
import DeleteButton from "../../components/Button/DeleteButton";
import EditButton from "../../components/Button/EditButton";
import CardLayout from "../../components/Layout/CardLayout";
import ReactDataTable from "../../components/ReactDataTable/ReactDataTable";
import ReactTable from "../../components/ReactDataTable/ReactTable";
import useFetch from "../../hooks/useFetch";
import request from "../../request/request";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../components/Modal/DeleteModal";

const HubList = () => {
  const navigate = useNavigate();
  const { result, isLoading, setRefresh } = useFetch(() =>
    request.getAll("hub-management"),
  );

  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    selectedId: "",
  });

  console.log(result);
  const columns = [
    {
      key: "hub_name",
      text: "Hub Name",
    },
    {
      key: "hub_info",
      text: "Hub Info",
    },
    {
      key: "service_area",
      text: "Service Area",
      cell: (row) => (
        <ul>
          {row?.service_area_info?.map((item) => (
            <li>{item?.name}</li>
          ))}
        </ul>
      ),
    },
    {
      key: "service_name",
      text: "Service Name",
      cell: (row) => (
        <ul>
          {row?.service_name_info?.map((item) => (
            <li>{item?.module_name}</li>
          ))}
        </ul>
      ),
    },
    {
      key: "hub_users",
      text: "Hub Users",
      cell: (row) => (
        <ul>
          {row?.hub_users_info?.map((item) => (
            <li>
              {item?.f_name} {item?.l_name}{" "}
            </li>
          ))}
        </ul>
      ),
    },
    {
      key: "status",
      text: "Status",
      cell: (row) => (row?.status === 1 ? "Active" : "Inactive"),
    },
    {
      key: "action",
      text: "Action",
      cell: (row) => (
        <div>
          <EditButton
            onClick={() => navigate(`/dashboard/hub-management/edit/${row.id}`)}
          />
          <DeleteButton
            onClick={() => setDeleteModal({ isOpen: true, selectedId: row.id })}
          />
        </div>
      ),
    },
  ];

  const deleteHubHandler = () =>
    request.delete("hub-management", deleteModal.selectedId, () => {
      setDeleteModal({ isOpen: false, selectedId: "" });
      setRefresh((prev) => !prev);
    });

  return (
    <CardLayout
      title="Hub Management"
      buttonTitle={"Create Hub"}
      addButtonHandler={() => navigate("/dashboard/hub-management/add")}
    >
      <ReactTable columns={columns} data={result} isLoading={isLoading} />

      {deleteModal.isOpen && (
        <DeleteModal
          isOpen={deleteModal.isOpen}
          onClose={() => setDeleteModal({ isOpen: false, selectedId: "" })}
          handleDelete={deleteHubHandler}
        />
      )}
    </CardLayout>
  );
};

export default HubList;
