import React from 'react';
import { Form } from 'react-bootstrap';

const FormLabel = ({ title, required, htmlFor, className, ...rest }) => (
    <Form.Label htmlFor={htmlFor} className={`fw-bold ${className} `} {...rest}>
        {title} {required && <span style={{ color: 'red' }}>*</span>}
    </Form.Label>
);

export default FormLabel;
