import axios from "axios";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import "./HeadBalance.scss";
import { format, subDays } from "date-fns";

const HeadBalance = () => {
  const [headBalance, setHeadBalance] = useState([]);
  const [headGroup, setHeadGroup] = useState([]);
  const [accountHead, setAccountHead] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const thirtyDaysAgo = subDays(new Date(), 30);

  const handlerOnSearch = (e) => {
    e.preventDefault();

    const form = e.target;
    const fromDate = form.from_date.value;
    const toDate = form.to_date.value;
    const head_group_id = form.head_group_id.value;

    const filterData = {
      fromDate,
      toDate,
      head_group_id: head_group_id.trim(),
    };

    methodFilterPatientsVoucherList(filterData);
  };

  const methodFilterPatientsVoucherList = async (filterData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/voucher/head-balance/search`,
        filterData
      );
      setHeadBalance(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      textAlign: "left",
    }),
    menu: (provided) => ({
      ...provided,
      textAlign: "left",
    }),
  };

  useEffect(() => {
    const fetchAPI = async () => {
      const filterData = {
        fromDate: format(thirtyDaysAgo, "yyyy-MM-dd"),
        toDate: format(new Date(), "yyyy-MM-dd"),
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/voucher/head-balance/search`,
        filterData
      );
      setHeadBalance(response?.data);
      const accountHead = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/account-head`
      );

      const accHeadData = accountHead?.data?.data;

      setAccountHead(accHeadData);
      setRefresh(refresh);
    };

    const fetchHeadGroup = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/head-group/all`
      );
      const data = res?.data?.data;
      setHeadGroup(data);
    };
    fetchHeadGroup();
    fetchAPI();
  }, []);

  return (
    <>
      <>
        <div className="card">
          <div className="card-body">
            <div className="border p-3 rounded">
              <div className="card-box">
                <h6 className="mb-0 text-uppercase">Head Balance</h6>
              </div>

              <hr />
              <div className="shadow-none">
                <div className="border p-1 rounded">
                  <div className="">
                    <form onSubmit={(e) => handlerOnSearch(e)}>
                      <div className="d-flex justify-content-start gap-3">
                        <div className="w-25">
                          <label className="col-form-label">
                            From <span className="text-danger">*</span>
                          </label>
                          <div className="">
                            <input
                              type="date"
                              data-date-format="YYYY MM DD"
                              name="from_date"
                              className="form-control"
                              placeholder="Date Picker..."
                              required
                              defaultValue={format(thirtyDaysAgo, "yyyy-MM-dd")}
                            />
                          </div>
                        </div>
                        <div className="w-25">
                          <label className=" col-form-label">
                            To <span className="text-danger">*</span>
                          </label>
                          <div className="">
                            <input
                              type="date"
                              data-date-format="YYYY MM DD"
                              name="to_date"
                              className="form-control"
                              placeholder="Date Picker..."
                              required
                              defaultValue={format(new Date(), "yyyy-MM-dd")}
                            />
                          </div>
                        </div>
                        <div className="w-25">
                          <label className=" col-form-label">Head Group</label>
                          <div className="">
                            <Select
                              className="patient-form-select"
                              name="head_group_id"
                              options={[
                                { value: "", label: "Choose One..." },
                                ...headGroup.map((from_headGroup, i) => ({
                                  value: from_headGroup.id,
                                  label: from_headGroup.name,
                                })),
                              ]}
                              placeholder="Choose One..."
                              isSearchable={true}
                              styles={customStyles}
                            />
                          </div>
                        </div>

                        <div className="d-flex justify-content-end align-items-end patient-info-search">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{ width: "6rem" }}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="datatable-scroll-container mt-4">
                <div className="accordion" id="accordionExample">
                  {headBalance?.map((group, index) => (
                    <div className="accordion-item" key={index}>
                      <h2
                        className="accordion-header"
                        id={`heading${index + 1}`}
                      >
                        <button
                          className="accordion-button collapsed custom-accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${index + 1}`}
                          aria-expanded="false"
                          aria-controls={`collapse${index + 1}`}
                        >
                          <span className="accordion-item-name w-100 d-flex justify-content-between">
                            <span>{group?.head_group_name}</span>
                            <span className="me-4">Total: {group?.total}</span>
                          </span>
                        </button>
                      </h2>
                      <div
                        id={`collapse${index + 1}`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`heading${index + 1}`}
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <div className="col-sm-12 d-flex font-weight-bold border-bottom">
                            <div className="col-sm-3">Head Name</div>
                            <div className="col-sm-9 text-end">Amount</div>
                          </div>
                          {group?.accounts?.map((account, accountIndex) => (
                            <div
                              key={accountIndex}
                              className={`col-sm-12 d-flex ${
                                accountIndex !== 0 ? "border-top" : ""
                              } ${
                                accountIndex === group?.accounts?.length - 1
                                  ? "border-top"
                                  : ""
                              }`}
                            >
                              <div className="col-sm-3">
                                <div className="">
                                  {account?.account_head_name}
                                </div>
                              </div>
                              <div className="col-sm-9 text-end">
                                <span>{account?.amount}</span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default HeadBalance;
