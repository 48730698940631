import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { toast } from "react-hot-toast";
import DatePicker from "react-datepicker";

export default function Schedule({ item, showSchedule, setShowSchedule, fetchData, refresh, setRefresh }) {
  // set time date state
  const [startDate, setStartDate] = useState(new Date());
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

//   console.log("startDate", item);

  const handleSchedule =  ()=>{
    axios
    .put(
      `${process.env.REACT_APP_API_BASE_URL}/services/request/test/set-schedule`
    ,{
        req_no : item.req_no,
        date : startDate,
        user_id : item.user_id,
    })
    .then((response) => {
      console.log("diagnosis-reqs", response);
      if(response.data.status === "OK"){
        toast.success("Schedule set Successfully")
        setShowSchedule(false)
        setRefresh(!refresh)
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  return (
    <div>
      <Modal
        // scrollable={true}
        show={showSchedule}
        onHide={() => {setShowSchedule(false)}}
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>View Payment Info & Set Schedule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  {/* <th>#</th> */}
                  <th>Request No</th>
                  {/* <th>Requested By</th> */}
                  <th>Total Amount</th>
                  <th>Paid Amount</th>
                  <th>Set Schedule Date And Time</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{item.req_no} </td>
                  {/* <td>{item.test_requester?.f_name} </td> */}
                  <td>{item.total_amount} </td>
                  <td>{item.paid_amount} </td>
                  <td>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      showTimeSelect
                      filterTime={filterPassedTime}
                      dateFormat="MMMM d, yyyy h:mm aa"
                    />
                  </td>
                  <td>
                    <Button
                      variant="success"
                      onClick={handleSchedule}
                    >
                      Set Schedule
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
