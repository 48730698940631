import React from 'react';
import useFetchData from "../../hooks/useFetchData";
import CardLayout from "../Layout/CardLayout";
import ReactTable from "../ReactDataTable/ReactTable";
import {getBloodReceivedStatus} from "../../utils/data/requestStatus";
import {getBloodGroupsName} from "../../utils/data/bloogGroup";

const BloodReceivedList = ({user_id}) => {
    const {data} = useFetchData(`services/service-history/user/blood-received/${user_id}`)

    const columns = [
        {
            key: "req_no",
            text: "Request No:",
            align: "center",
            sortable: true,
        },
        {
            key: "req_blood_group",
            text: "Blood Group:",
            align: "center",
            sortable: false,
            cell: row => getBloodGroupsName(row?.req_blood_group)
        },
        {
            key: "collection_point",
            text: "Collection Point:",
            align: "center",
            sortable: false,
            cell: row => <span>{row?.col_point?.f_name}</span>
        },
        {
            key: "col_address",
            text: "Collection Address:",
            align: "center",
            sortable: false,
            cell: row => <span>{row?.col_point?.address_1}</span>
        },
        {
            key: "date_time",
            text: "Donation Date",
            align: "center",
            sortable: false,
        },
        {
            key: "don_by",
            text: "Donated By",
            align: "center",
            sortable: false,
            cell: row => <span>{row?.donor?.f_name}</span>
        },
        {
            key: "investigation_ids\n",
            text: "Investigation",
            align: "center",
            sortable: false,
        },
        {
            key: "status",
            text: "Status",
            align: "center",
            sortable: true,
            cell: row => <span>{getBloodReceivedStatus(row?.status)}</span>
        },
    ]

    console.log(data?.data);


    return (
        <section>
            <CardLayout title={"Blood Received History"}>
                <ReactTable
                    data={data?.data}
                    columns={columns}
                />
            </CardLayout>

        </section>
    );
};

export default BloodReceivedList;