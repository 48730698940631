import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const ReactTextEditor = ({ setEditorData, defaultData }) => (
  <CKEditor
    editor={ClassicEditor}
    data={defaultData || "  "} // You can set initial content here if needed.
    // onReady={(editor) => {
    //     // You can store the "editor" and use when it is needed.
    //     console.log('Editor is ready to use!', editor);
    // }}
    onChange={(event, editor) => {
      const data = editor.getData();
      // console.log({ event, editor, data });
      // console.log(editor.getData());
      setEditorData(data);
    }}
    config={{
      toolbar: [
        "heading",
        "|",
        "bold",
        "italic",
        "link",
        "bulletedList",
        "numberedList",
        "blockQuote",
      ],
    }}
    // onBlur={(event, editor) => {
    //     console.log('Blur.', editor);
    // }}
    // onFocus={(event, editor) => {
    //     console.log('Focus.', editor);
    // }}
  />
);

export default ReactTextEditor;
