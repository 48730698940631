import React from 'react';
import axios from "axios";
import {toast} from "react-hot-toast";
import DeleteModal from "../../../components/Modal/DeleteModal";

const DeleteRoom = ({isOpen, onClose, deleteId, refresh}) => {

    const handleDeleteRoom = () => {
        axios
            .delete(`${process.env.REACT_APP_API_BASE_URL}/room/${deleteId}`)
            .then((response) => {
                if (response.status === 204) {
                    toast.success('Room deleted successfully');
                    refresh();
                    onClose();
                }
            })
            .catch((error) => console.log(error));
    }
    return (
        <div>

            <DeleteModal
                isOpen={isOpen}
                onClose={onClose}
                handleDelete={handleDeleteRoom}
            />

        </div>
    );
};

export default DeleteRoom;