import React, {useState} from 'react';
import useFetchData from "../../hooks/useFetchData";
import {Col, Form, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import toast from "react-hot-toast";
import axios from "axios";
import ReactTable from "../ReactDataTable/ReactTable";
import CardLayout from "../Layout/CardLayout";

const HealthManagerFiles = ({user_id}) => {

    // const {currentUser} = useContext(UserContext)
    // console.log(currentUser)

    const [clicked, setClicked] = useState(false);

    const {data, setRefresh} = useFetchData(`health-manager/get-files/${user_id}`, user_id)

    const {data: fileTypesData} = useFetchData(`file-upload-type`);


    const [allowedFileTypes, setAllowedFileTypes] = useState('')

    console.log(data)
    const uploadFileHandler = async (event) => {
        setClicked(true);
        try {
            event.preventDefault();

            const {title, file} = {...Object.fromEntries(new FormData(event.currentTarget))}


            if (title === '' || file === '' || !file) {

                toast.error("No title given / No file selected")
                return setClicked(false);

            }


            if (file?.size / 1024 >= allowedFileTypes?.file_size) {
                return toast.error(`File size is  cannot be larger than ${allowedFileTypes?.file_size} kb`)
            }

            const formData = new FormData()
            formData.append("file", file)

            const fileUpload = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/health-manager/upload-file`, formData)

            const filePath = fileUpload.data.filename
            if (filePath) {

                const obj = {
                    user_id: user_id,
                    fileType: allowedFileTypes?.id,
                    title: title,
                    file: filePath
                }

                console.log(obj);

                const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/health-manager/add-file`, obj)

                console.log(res)

                if (res.status === 200) {
                    toast.success("file Uploaded successfully")
                    setClicked(false);
                    event.target.reset();
                    setRefresh(prevState => !prevState)
                }

            }

        } catch (e) {
            console.log(e)
        }
    }

    const handleSelectFileType = (value) => {
        setAllowedFileTypes(fileTypesData?.data?.find(type => type.id === parseInt(value)))
    }


    return (
        <div>

            <h4>Upload Medical Documents</h4>

            <Form onSubmit={uploadFileHandler}>
                <Row>
                    <Form.Group as={Col} sm={12} md={3}>
                        <Form.Label>Select FIle Type</Form.Label>
                        <Form.Select required name='fileType' onChange={(e) => handleSelectFileType(e.target?.value)}>
                            <option value="">Select Type</option>
                            {!!fileTypesData?.data && fileTypesData?.data?.map((fileTypesDatum) => (
                                <option value={fileTypesDatum.id}
                                        key={fileTypesDatum?.id}>
                                    {fileTypesDatum.name}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group as={Col} sm={12} md={3}>
                        <Form.Label>File Title</Form.Label>
                        <Form.Control type='text' name='title' required placeholder='File Title'/>
                    </Form.Group>

                    <Form.Group as={Col} sm={12} md={4}>
                        <Form.Label> Select ({allowedFileTypes?.extensions})
                            Max {allowedFileTypes?.file_size} KB </Form.Label>
                        <Form.Control type='file' name='file' required accept={allowedFileTypes?.extensions}/>
                    </Form.Group>

                    <Col sm={12} md={1} className='mt-4'>
                        <Button type='submit' disabled={clicked}>Upload</Button>
                    </Col>

                </Row>
            </Form>

            <Row className='mt-4'>
                <CardLayout title={'Health Files List'}>
                    <HealthManagerFileShow data={data?.data}/>
                </CardLayout>
            </Row>

        </div>
    );
};

export default HealthManagerFiles;


export const HealthManagerFileShow = ({data}) => {

    const downloadHandler = async (file) => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/health-manager/get-files?fileName=${file.file}`,
                {
                    responseType: "blob",
                },
            );
            console.log(res.data)
            const blob = new Blob([res.data]);
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = file.file; // Specify the desired filename
            a.click();
            window.URL.revokeObjectURL(url);
            toast.success("File Downloaded Successfully");
        } catch (err) {
            console.log(err);
        }
    };


    const columns = [
        {
            key: "id",
            text: "#",
            align: "center",
            sortable: true,
            cell: (rowData, i) => i + 1
        },
        {
            key: "title",
            text: "File Title",
            align: "center",
            sortable: true,
        },
        {
            key: "file_type",
            text: "File Type",
            align: "center",
            sortable: true,
            cell: rowData => <span>{rowData?.file_upload_type?.name}</span>
        },
        {
            key: "action",
            text: "Download File",
            align: "center",
            sortable: true,
            cell: rowData => <Button size='sm' onClick={() => downloadHandler(rowData)}>Download</Button>
        },
    ];

    return (
        <div>
            <ReactTable
                data={data}
                columns={columns}
            />
        </div>
    )
}