import React, { useState } from "react";
import CardLayout from "../../../components/Layout/CardLayout";
import CreateKnowledgeBase from "./CreateKnowledgeBase";
import useFetchData from "../../../hooks/useFetchData";
import Button from "react-bootstrap/Button";
import ReactTable from "../../../components/ReactDataTable/ReactTable";
import Modal from "react-bootstrap/Modal";
import UpdateKnowledgeBase from "./UpdateKnowledgeBase";
import axios from "axios";
import toast from "react-hot-toast";
import DeleteModal from "../../../components/Modal/DeleteModal";
import { useNavigate } from "react-router-dom";

const KnowledgeBaseList = () => {
  const navigate = useNavigate();

  const [modelOpen, setModalOpen] = useState("");
  const [selectedItem, setSelectedItem] = useState("");

  const { data: knowledgeBaseCat } = useFetchData("knowledge-base-cat");

  // get all knowledge base
  const { data: knowledgeBase, setRefresh } = useFetchData("knowledgeBase");

  // console.log(knowledgeBase?.data)

  const columns = [
    {
      key: "image",
      text: "Image",
      align: "left",
      sortable: false,
      cell: (record) => (
        <img
          src={`${process.env.REACT_APP_UPLOAD_URL}/knowledgeBase/${record?.image}`}
          alt=""
          width={100}
          height={70}
          className="rounded"
        />
      ),
    },
    {
      key: "title",
      text: "Title",
      align: "left",
      sortable: true,
    },
    {
      key: "category_id",
      text: "Category",
      align: "left",
      sortable: true,
      cell: (record) => <span>{record?.category?.name}</span>,
    },
    {
      key: "video",
      text: "Video",
      align: "left",
      sortable: false,
      cell: (record) =>
        record?.video && (
          <Button
            onClick={() => {
              setModalOpen("video");
              setSelectedItem(record);
            }}
          >
            See Video
          </Button>
        ),
    },

    // {
    //   key: "info",
    //   text: "Info",
    //   className: "info",
    //   align: "left",
    //   sortable: false,
    // },
    {
      key: "order_by",
      text: "Order By",
      className: "info",
      align: "left",
      sortable: false,
    },
    {
      key: "status",
      text: "Status",
      className: "status",
      sortable: true,
      cell: (record) => {
        return <>{record.status === 1 ? "Active" : "Inactive"}</>;
      },
    },
    {
      key: "action",
      text: "Action",
      sortable: false,
      width: 150,
      cell: (record) => {
        return (
          <>
            <Button
              type="button"
              size="sm"
              className="me-2"
              onClick={() => {
                navigate(`/dashboard/knowledge-base/list/edit/${record.id}`);
              }}
            >
              <i className="fa fa-edit"></i>
            </Button>
            <Button
              type="button"
              variant="danger"
              size="sm"
              className="me-2"
              onClick={() => {
                setModalOpen("delete");
                setSelectedItem(record);
              }}
            >
              <i className="fa fa-trash"></i>
            </Button>
          </>
        );
      },
    },
  ];

  const deleteHandler = async () => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/knowledgeBase/${selectedItem?.id}`,
      );

      console.log(res);
      if (res.data?.status === "OK") {
        toast.success("Updated knowledge base category successfully");
        setModalOpen("");
        setRefresh((prevState) => !prevState);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <CardLayout
      title="Knowledge base List"
      buttonTitle="Create New Knowledge Base"
      addButtonHandler={() => navigate("/dashboard/knowledge-base/list/add")}
    >
      <ReactTable columns={columns} data={knowledgeBase?.data} />

      {modelOpen === "create" && (
        <CreateKnowledgeBase
          isOpen={modelOpen === "create"}
          onClose={() => setModalOpen("")}
          knowledgeBaseCat={knowledgeBaseCat?.data}
          refresh={() => setRefresh((prevState) => !prevState)}
        />
      )}

      {modelOpen === "video" && (
        <ShowVideoInModal
          isOpen={modelOpen === "video"}
          onClose={() => setModalOpen("")}
          videoUrl={selectedItem?.video}
        />
      )}

      {modelOpen === "update" && (
        <UpdateKnowledgeBase
          isOpen={modelOpen === "update"}
          onClose={() => setModalOpen("")}
          knowledgeBaseCat={knowledgeBaseCat?.data}
          editData={selectedItem}
          refresh={() => setRefresh((prevState) => !prevState)}
        />
      )}

      {modelOpen === "delete" && (
        <DeleteModal
          isOpen={modelOpen === "delete"}
          onClose={() => setModalOpen("")}
          handleDelete={() => deleteHandler()}
        />
      )}
    </CardLayout>
  );
};

export default KnowledgeBaseList;

export const ShowVideoInModal = ({ videoUrl, isOpen, onClose }) => {
  console.log(videoUrl)
  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <h5>Video</h5>
      </Modal.Header>
      <Modal.Body>
        <video
          src={videoUrl ? `${process.env.REACT_APP_UPLOAD_URL}/knowledgeBase/${videoUrl}` : ""}
          height={"100%"}
          width={"100%"}
          controls
          type="video/*"
        >
          Your browser does not support the video tag.
        </video>
      </Modal.Body>
    </Modal>
  );
};
