import React from 'react';
import { useField } from 'formik';
import { Form, FormControl, InputGroup } from 'react-bootstrap';

const ReactInput = ({ GroupComponent, isImage, ...props }) => {
    const [field, meta, { setValue }] = useField(props);

    if (props?.type === 'file') {
        return (
            <InputGroup>
                <Form.Control
                    type='file'
                    // accept='image/*'
                    onChange={(event) => {
                        const imageFile = event.currentTarget.files[0];
                        setValue(imageFile);
                    }}
                    {...props}
                    isInvalid={meta.touched && meta.error}
                />
                {GroupComponent && GroupComponent}
                {meta.touched && meta.error && (
                    <FormControl.Feedback type='invalid'>{meta?.error}</FormControl.Feedback>
                )}
            </InputGroup>
        );
    }

    return (
        <InputGroup>
            <Form.Control
                placeholder='Type here'
                {...field}
                {...props}
                isInvalid={meta.touched && meta.error}
            />
            {GroupComponent && GroupComponent}
            {meta.touched && meta.error && (
                <FormControl.Feedback type='invalid'>{meta?.error}</FormControl.Feedback>
            )}
        </InputGroup>
    );
};

export default ReactInput;
