import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import React, {useContext, useEffect, useState} from 'react';
import {UserContext} from '../../../Context/UserContextAPI';
import AddNewRoom from "./AddNewRoom";
import UpdateRoom from "./UpdateRoom";
import DeleteRoom from "./DeleteRoom";

const roomUrl = `${process.env.REACT_APP_API_BASE_URL}/room`;

const Room = () => {
    const {accessPerm} = useContext(UserContext);

    const [modalType, setModalType] = useState(''); // create , update , delete
    const [accommodationList, setAccommodationList] = useState([]);
    const [hospitalList, setHospitalList] = useState([]);
    const [data, setData] = useState([]);
    const [checked, setChecked] = useState();
    const [form, setForm] = useState({
        name: '',
        floor: '',
        info: '',
        status: checked ? '1' : '0',
    });

    const [selectedItem, setSelectedItem] = useState({})


    const columns = [
        {
            key: 'name',
            text: 'Name',
            align: 'left',
            sortable: true,
        },
        {
            key: 'floor',
            text: 'Floor',
            align: 'left',
            sortable: true,
        },
        {
            key: 'accommodation_type_id',
            text: 'Accommodation Type',
            align: 'left',
            sortable: true,
            cell: data => <span>{data?.accommodation_types?.name}</span>
        },
        {
            key: 'hospital_id',
            text: 'Hospital',
            align: 'left',
            sortable: true,
            cell: data => <span>{data?.hospital?.f_name}</span>

        },
        {
            key: 'price',
            text: 'price',
            align: 'left',
            sortable: true,
            cell: data => <span>{data?.price} TK</span>

        },
        {
            key: 'info',
            text: 'Info',
            className: 'info',
            align: 'left',
            sortable: true,
        },
        {
            key: 'status',
            text: 'Status',
            className: 'status',
            sortable: true,
            cell: (record) => {
                return <>{record.status === 1 ? "Active" : "Inactive"}</>;
            },
        },
        {
            key: 'action',
            text: 'Action',
            className: 'action',
            width: 100,
            align: 'left',
            sortable: false,
            cell: (data) => {
                return (
                    <>
                        <div className='col'>
                            {/* Button trigger modal */}
                            {accessPerm(12, 2) && (
                                <button
                                    type='button'
                                    className='btn btn-primary btn-sm'
                                    style={{marginRight: '5px'}}
                                    onClick={() => {
                                        setModalType('update')
                                        setSelectedItem(data)
                                    }}
                                >
                                    <i className='fa fa-edit'></i>
                                </button>
                            )}


                        </div>
                        <div className='col'>
                            {/* Button trigger modal */}
                            {accessPerm(13, 3) && (
                                <button
                                    type='button'
                                    className='btn btn-danger btn-sm'
                                    style={{marginRight: '5px'}}
                                    onClick={() => {
                                        setModalType('delete')
                                        setSelectedItem(data)
                                    }}
                                >
                                    <i className='fa fa-trash'></i>
                                </button>
                            )}

                        </div>
                    </>
                );
            },
        },
    ];
    const config = {
        page_size: 10,
        show_filter: true,
        show_length_menu: true,
        show_pagination: true,
        pagination: 'advance',
        length_menu: [10, 50, 100],
        button: {
            excel: true,
            print: true,
            extra: true,
        },
    };


    const fetchData = () => {
        axios
            .get(roomUrl)
            .then((response) => {

                if (response.data.data) {
                    setData(response.data.data)
                }

            })
            .catch((err) => console.log(err));
    };

    const getAccommodationType = () => {
        axios
            .get(`${process.env.REACT_APP_API_BASE_URL}/settings/accommodation/active`)
            .then((response) => {
                if (response.data.status === 'OK') {
                    setAccommodationList(response.data?.data)
                }

            })
            .catch((err) => console.log(err));
    }

    const getAllHospitalList = () => {
        axios
            .get(`${process.env.REACT_APP_API_BASE_URL}/users/get-all/role/serviceCat?role_id=13&&service_cat=13`)
            .then((response) => {
                if (response.data.status === 'SUCCESS') {
                    setHospitalList(response.data?.data)
                }

            })
            .catch((err) => console.log(err));
    }


    useEffect(() => {
        fetchData();
        getAccommodationType();
        getAllHospitalList();
    }, [form]);

    const extraButtons = [
        // {
        //     className:"btn btn-primary buttons-pdf",
        //     title:"Export TEst",
        //     children:[
        //         <span>
        //         <FaRegFilePdf/>
        //         </span>
        //     ],
        //     onClick:(event)=>{
        //         console.log(event);
        //     },
        // },
        // {
        //     className:"btn btn-primary buttons-pdf",
        //     title:"Export TEst",
        //     children:[
        //         <span>
        //         <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
        //         </span>
        //     ],
        //     onClick:(event)=>{
        //         console.log(event);
        //     },
        //     onDoubleClick:(event)=>{
        //         console.log("doubleClick")
        //     }
        // }
    ];
    console.log(data)
    return (
        <>
            <div className='card'>
                <div className='card-body'>
                    <div className='border p-3 rounded'>
                        <div className='card-box'>
                            <h6 className='mb-0 text-uppercase'>Room Management</h6>
                            <div className='col'>
                                {/* Button trigger modal */}
                                {accessPerm(13, 1) && (
                                    <button
                                        type='button'
                                        className='btn btn-primary'
                                        onClick={() => {
                                            setModalType('create')
                                        }}
                                    >
                                        <i className='fa-solid fa-plus'></i> Add New
                                    </button>
                                )}

                            </div>
                        </div>

                        <hr/>

                        <ReactDatatable
                            config={config}
                            records={data}
                            columns={columns}
                            extraButtons={extraButtons}
                        />
                    </div>
                </div>
            </div>

            {
                modalType === 'create' && <AddNewRoom
                    isOpen={!!modalType}
                    onClose={() => setModalType('')}
                    accommodationList={accommodationList}
                    hospitalList={hospitalList}
                    refresh={fetchData}
                />
            }

            {/*Update */}


            {
                modalType === 'update' && <UpdateRoom
                    isOpen={!!modalType}
                    onClose={() => setModalType('')}
                    accommodationList={accommodationList}
                    hospitalList={hospitalList}
                    editData={selectedItem}
                    refresh={fetchData}
                />
            }

            {/*delete Room*/}

            {
                modalType === 'delete' && <DeleteRoom
                    isOpen={!!modalType}
                    onClose={() => setModalType('')}
                    deleteId={selectedItem?.id}
                    refresh={fetchData}
                />
            }


        </>
    );
};

export default Room;
