import axios from "axios";
import React, {useEffect, useState} from "react";
import {toast} from 'react-hot-toast';
import {useNavigate} from "react-router-dom";

export default function UploadDonor() {
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(0);
    const [selectedState, setSelectedState] = useState(0);
    const [selectedCity, setSelectedCity] = useState(0);
    const [excelFile, setExelFile] = useState('');
    const [uploadingFile, setUploadingFile] = useState(false);

    const navigate = useNavigate()

    const fetchCountryList = () => {
        axios
            .get(`${process.env.REACT_APP_API_BASE_URL}/city/country/data`)
            .then((response) => setCountryList(response.data.data))
            .catch((err) => console.log(err));
    };

    const fetchStateList = () => {
        axios
            .get(
                `${process.env.REACT_APP_API_BASE_URL}/city/state/data/${selectedCountry?.id}`
            )
            .then((response) => {
                setStateList(response.data.data);
            })
            .catch((err) => console.log(err));
    };
    const fetchCityList = () => {
        axios
            .get(
                `${process.env.REACT_APP_API_BASE_URL}/city/data/${selectedState?.id}`
            )
            .then((response) => {
                setCityList(response.data.data);
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        fetchCountryList();

    }, [])

    useEffect(() => {
        fetchStateList();
        fetchCityList();

    }, [selectedCountry, selectedState])

    // console.log(selectedCountry)


    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            if (selectedCountry && selectedState && selectedCity && excelFile) {

                setUploadingFile(true)
                const formData = new FormData();
                formData.append('file', excelFile);


                // console.log('obj', obj)
                const uploadExcel = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/upload/excel`, formData);
                console.log("uploadExcel", uploadExcel.data)
                if (uploadExcel.status === 200) {
                    const obj = {
                        country: selectedCountry.id,
                        state: selectedState.id,
                        city: selectedCity.id,
                        fileName: uploadExcel.data.filename
                    }
                    const uploadData = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/upload/bulk-donor`, obj);
                    console.log('uploadData', uploadData)
                    if (uploadData.data) {
                        toast.success("Bulk donor list created");
                        navigate('/dashboard/roles/10')
                    }
                }


            } else {
                toast.error("Please fill up all the filed")
            }

        } catch (error) {
            console.log(error)
        } finally {
            setUploadingFile(false)
        }

    }

    const downloadSampleFile = async () => {
        try {
            const file = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/download/excel/sample`, {
                responseType: "blob",
            })
            console.log(file.data);
            if (file) {
                const blob = new Blob([file.data]);
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = 'donor-upload-sample-file.xlsx'; // Specify the desired filename
                a.click();
                window.URL.revokeObjectURL(url);
                toast.success("File Downloaded Successfully");
            }

        } catch (error) {
            console.log(error)
            toast.error("file is not exist")
        }
    }

    return (
        <div className="container">
            <div className="card w-75">
                <div className="card-body">
                    <div className="border p-3 rounded">
                        <div className="card-box">
                            <h6 className="mb-0 text-uppercase">Upload Donor excel File</h6>
                        </div>

                        <hr/>
                        <form className="" onSubmit={handleSubmit}>
                            <div className="row mb-3 d-flex align-items-center">
                                <label className="col-sm-3 col-form-label">
                                    Country <span className="text-danger">*</span>
                                </label>
                                <div className="col-sm-9">
                                    <select
                                        name="adSection"
                                        id=""
                                        className="single-select form-select"
                                        onChange={(e) => {
                                            setSelectedCountry(
                                                countryList.find((country) => {
                                                    return country.id == e.target.value;
                                                }),
                                            );
                                        }}
                                    >
                                        <option value="" selected disabled>Select Country</option>

                                        {countryList.map((county) => (
                                            <option key={county?.id} value={county?.id}>
                                                {county?.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="row mb-3 d-flex align-items-center">
                                <label className="col-sm-3 col-form-label">
                                    State <span className="text-danger">*</span>
                                </label>
                                <div className="col-sm-9">
                                    <select
                                        name="adSection"
                                        id=""
                                        className="single-select form-select"
                                        onChange={(e) => {
                                            setSelectedState(
                                                stateList.find((state) => {
                                                    return state.id == e.target.value;
                                                }),
                                            );
                                        }}
                                    >

                                        <option value="" selected disabled>Select State</option>
                                        {stateList.map((county) => (
                                            <option key={county?.id} value={county?.id}>
                                                {county?.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="row mb-3 d-flex align-items-center">
                                <label className="col-sm-3 col-form-label">
                                    City <span className="text-danger">*</span>
                                </label>
                                <div className="col-sm-9">
                                    <select
                                        name="adSection"
                                        id=""
                                        className="single-select form-select"
                                        onChange={(e) => {
                                            setSelectedCity(
                                                cityList.find((city) => {
                                                    return city.id == e.target.value;
                                                }),
                                            );
                                        }}
                                    >

                                        <option value="" selected disabled>Select City</option>
                                        {cityList.map((county) => (
                                            <option key={county?.id} value={county?.id}>
                                                {county?.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>


                            <div className="row mb-3 d-flex align-items-center">
                                <label className="col-sm-3 col-form-label">
                                    Upload Excel <span className="text-danger">*</span>
                                </label>
                                <div className="col-sm-9">
                                    <input
                                        type="file"
                                        name="file"
                                        accept=".xlsx , .xls"
                                        onChange={(e) => setExelFile(e.target.files[0])}
                                    />
                                </div>
                            </div>

                            <div className="row mb-3 d-flex align-items-center">
                                <label className="col-sm-3 col-form-label">
                                    Sample Excel File
                                </label>
                                <div className="col-sm-9">
                                    <button type='button' onClick={downloadSampleFile}
                                            className="btn btn-primary btn-sm">Download
                                    </button>
                                </div>
                            </div>
                            <div className="text-end">
                                <button type="submit" disabled={uploadingFile}
                                        className="btn btn-primary btn-sm">{uploadingFile ? "Uploading..." : "Upload"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
