import React from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';

function FilterForm({apiHandler, isServiceSearch}) {

    const services = [
        {
            id: 1,
            title: "Health_Manager_Subscription"
        },
        {
            id: 2,
            title: "Investigation"
        },
        {
            id: 3,
            title: "Medicine Request"
        },
        {
            id: 4,
            title: "Ambulance Request"
        },
        {
            id: 5,
            title: "Doctor Appointment Request"
        },
        {
            id: 6,
            title: "Treatment Request"
        },
        {
            id: 7,
            title: 'Health Products'
        }
    ]

    const formController = (event) => {
        event.preventDefault();
        const form = event.target;

        const formData = {
            start_date: form?.start_date?.value,
            end_date: form?.end_date?.value,
            service: form?.services?.value
        }

        apiHandler(formData);
    }

    return (
        <Form onSubmit={formController}>
            <Row className={'align-items-center'}>
                <Col>
                    <Form.Group controlId="startDate">
                        <Form.Label className={'text-center'}>Start Date</Form.Label>
                        <Form.Control type="date" name={'start_date'} required/>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="endDate">
                        <Form.Label>End Date</Form.Label>
                        <Form.Control type="date" name={'end_date'} required/>
                    </Form.Group>
                </Col>
                {isServiceSearch && <Col>
                    <Form.Group controlId="selectServices">
                        <Form.Label>Select Services</Form.Label>
                        <Form.Control as="select" name={'services'}>
                            {/* Add options for services */}
                            <option value="" selected>Select Service</option>
                            {
                                services.map(service => (
                                    <option key={service.id} value={service.title}>{service.title}</option>
                                ))
                            }
                        </Form.Control>
                    </Form.Group>
                </Col>}
                <Col>
                    <Button variant="primary" type="submit">
                        Search
                    </Button>
                </Col>
            </Row>
        </Form>
    );
}

export default FilterForm;
