import FormLayout from "../../Layout/FormLayout";
import HubManagementForm from "./HubManagementForm";

const HubManagementFormContainer = ({ apiHandler, editData }) => {
  const initialValues = {
    hub_name: "",
    hub_info: "",
    hub_country: 18,
    hub_state: 348,
    service_area: "",
    service_name: "",
    hub_users: "",
    status: 1,
  };

  const submitHandler = (values) => {
    console.log(values);
    values.service_area = values?.service_area
      ?.map((item) => item.value)
      ?.join();
    values.service_name = values?.service_name
      ?.map((item) => item.value)
      ?.join();
    values.hub_users = values?.hub_users?.map((user) => user.value)?.join();

    if (apiHandler) {
      return apiHandler(values);
    }
    return null;
  };

  return (
    <FormLayout
      initialValues={editData || initialValues}
      buttonCenter
      apiHandler={submitHandler}
    >
      <HubManagementForm />
    </FormLayout>
  );
};

export default HubManagementFormContainer;
