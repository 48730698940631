import React, {useContext, useEffect, useState} from 'react';
import ReactDatatable from "@ashvin27/react-datatable";
import {UserContext} from "../../../Context/UserContextAPI";
import AddAccommodationType from "./AddAccommodationType";
import axios from "axios";
import UpdateAccommodationType from "./UpdateAccommodationType";
import DeleteAccommodation from "./DeleteAccommodation";

const AccommodationType = () => {


    // states and hooks
    const {accessPerm} = useContext(UserContext);
    const [modalType, setModalType] = useState(''); // create , update , delete
    const [accommodationTypeList, setAccommodationTypeList] = useState([]);
    const [singleItem, setSingleItem] = useState({});


    // fetch data handler fn

    const getAccommodationTypeData = () => {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/settings/accommodation`,)
            .then(res => {
                if (res.data.status === "OK") {
                    setAccommodationTypeList(res.data?.data)
                }
                console.log(res.data)
            })
            .catch(err => console.log(err))
    }


    useEffect(() => {
        getAccommodationTypeData();
    }, []);


    const columns = [
        {
            key: "name",
            text: "Name",
            className: "name",
            align: "left",
            sortable: true,
            cell: (record) => {
                return <>{record?.name}</>;
            },
        },

        {
            key: "info",
            text: "Info",
            className: "info",
            sortable: true,
        },
        {
            key: "status",
            text: "Status",
            className: "status",
            sortable: true,
            cell: (record) => {
                return <>{record.status === 1 ? "Active" : "Inactive"}</>;
            },
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 100,
            align: "left",
            sortable: false,
            cell: (record) => {
                return (
                    <>
                        {/* Edit Drug Group Trigger Button */}
                        {accessPerm(11, 2) && (
                            <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                style={{marginRight: "5px"}}
                                onClick={() => {
                                    setSingleItem(record)
                                    setModalType('update');
                                }}
                            >
                                <i className="fa fa-edit"></i>
                            </button>
                        )}

                        {/* Delete Drug Group Trigger Button */}
                        {accessPerm(11, 3) && (
                            <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                style={{marginRight: "5px"}}
                                onClick={() => {
                                    setSingleItem(record)
                                    setModalType('delete');
                                }}
                            >
                                <i className="fa fa-trash"></i>
                            </button>
                        )}

                    </>
                );
            },
        },
    ];

    // tables config
    const config = {
        page_size: 10,
        show_filter: true,
        show_length_menu: true,
        show_pagination: true,
        pagination: "advance",
        length_menu: [10, 50, 100],

    };


    return (
        <div>
            <div className="card">
                <div className="card-body">
                    <div className="border p-3 rounded">
                        <div className="card-box">
                            <h6 className="mb-0 text-uppercase">Accommodation Type</h6>
                            <div className="col">
                                {/* Add new Accommodation Type trigger modal Button */}
                                {accessPerm(11, 1) && (
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => setModalType('create')}
                                    >
                                        <i className="fa-solid fa-plus"></i> Add New
                                    </button>
                                )}
                            </div>
                        </div>

                        <hr/>

                        <ReactDatatable
                            config={config}
                            records={accommodationTypeList}
                            columns={columns}
                            // extraButtons={extraButtons}
                        />
                    </div>
                </div>
            </div>

            {/*all the modal components */}

            {modalType === 'create' &&
                <AddAccommodationType
                    isOpen={!!modalType}
                    onClose={() => setModalType('')}
                    refresh={() => getAccommodationTypeData()}
                />}

            {modalType === 'update' &&
                <UpdateAccommodationType
                    isOpen={!!modalType}
                    onClose={() => setModalType('')}
                    editData={singleItem}
                    refresh={() => getAccommodationTypeData()}
                />}

            {modalType === 'delete' &&
                <DeleteAccommodation
                    isOpen={!!modalType}
                    onClose={() => setModalType('')}
                    data={singleItem}
                    refresh={() => getAccommodationTypeData()}
                />}

        </div>
    );
};

export default AccommodationType;