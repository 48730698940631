import React from 'react';
import CardLayout from "../Layout/CardLayout";
import useFetchData from "../../hooks/useFetchData";
import ReactTable from "../ReactDataTable/ReactTable";
import {getBloodGroupsName} from "../../utils/data/bloogGroup";

const BloodDonationList = ({user_id}) => {

    const {data} = useFetchData(`services/service-history/user/blood-donations/${user_id}`)

    const columns = [
        {
            key: "req_no",
            text: "Request No:",
            align: "center",
            sortable: true,
        },
        {
            key: "req_blood_group",
            text: "Blood Group:",
            align: "center",
            sortable: false,
            cell: row => getBloodGroupsName(row?.req_blood_group)
        },
        {
            key: "collection_point",
            text: "Collection Point:",
            align: "center",
            sortable: false,
            cell: row => <span>{row?.col_point?.f_name}</span>
        },
        {
            key: "col_address",
            text: "Collection Address:",
            align: "center",
            sortable: false,
            cell: row => <span>{row?.col_point?.address_1}</span>
        },
        {
            key: "date_time",
            text: "Donation Date",
            align: "center",
            sortable: false,
        },
        {
            key: "req_by",
            text: "Requested By",
            align: "center",
            sortable: false,
            cell: row => <span>{row?.req_by?.f_name}</span>
        },
        {
            key: "investigation_ids",
            text: "Investigation",
            align: "center",
            sortable: false,
        },
        {
            key: "status",
            text: "Status",
            align: "center",
            sortable: true,
        },
    ]

    console.log(data?.data);


    return (
        <section>
            <CardLayout title={"Blood Donation History"}>
                <ReactTable
                    data={data?.data}
                    columns={columns}
                />
            </CardLayout>

        </section>
    );
};

export default BloodDonationList;