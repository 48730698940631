import HubManagementFormContainer from "../../components/Form/HubManagement/HubManagementFormContainer";
import CardLayout from "../../components/Layout/CardLayout";
import request from "../../request/request";
import { useNavigate } from "react-router-dom";

const AddHubManagement = () => {
  const navigate = useNavigate();
  const createHubHandler = (formValues) => {
    return request.create("hub-management/add", formValues, () => {
      navigate("/dashboard/hub-management");
    });
  };
  return (
    <CardLayout title="Add Hub ">
      <HubManagementFormContainer apiHandler={createHubHandler} />
    </CardLayout>
  );
};

export default AddHubManagement;
