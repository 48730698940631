import React from "react";
import { Button, Form } from "react-bootstrap";
import { Formik } from "formik";

const FormLayout = ({
  children,
  onClose,
  apiHandler,
  initialValues,
  validationSchema,
  buttonCenter,
  noButton,
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={(values) => (apiHandler ? apiHandler(values) : null)}
    validationSchema={validationSchema}
    // validateOnBlur={false}
    // validateOnChange={false}
    // validateOnMount={false}
  >
    {(props) => {
      const { isSubmitting, handleSubmit, dirty, errors } = props;
      // console.log(errors);
      return (
        <Form onSubmit={handleSubmit}>
          <fieldset disabled={isSubmitting}>
            <div>{children}</div>

            <div
              className={`d-flex ${
                buttonCenter ? "justify-content-center" : "justify-content-end"
              } gap-3 mt-3`}
            >
              {onClose && (
                <Button variant="secondary" onClick={onClose}>
                  Close
                </Button>
              )}
              {!noButton && (
                <Button type="submit" variant="primary" disabled={!dirty}>
                  Save changes
                </Button>
              )}
            </div>
          </fieldset>
        </Form>
      );
    }}
  </Formik>
);

export default FormLayout;
