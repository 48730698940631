import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import ReactTextEditor from "../../../components/Editor/ReactTextEditor";
import useFetchData from "../../../hooks/useFetchData";

const KnowledgeBaseForm = ({ apiHandler, editData, submitting }) => {
  const { data: knowledgeBaseCat } = useFetchData("knowledge-base-cat");
  const [info, setInfo] = React.useState(editData ? editData?.info : "");

  const formHandler = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data = {
      ...Object.fromEntries(formData),
      status: event.target?.status.checked ? 1 : 0,
      info,
    };

    apiHandler(data);
  };

  return (
    <Form onSubmit={formHandler}>
      <Form.Group as={Row}>
        <Form.Label as={Col} sm={3}>
          Category <span className="text-danger">*</span>
        </Form.Label>
        <Col>
          <Form.Select
            name="category_id"
            required
            defaultValue={editData && editData?.category_id}
          >
            <option value="">Select Category</option>
            {knowledgeBaseCat &&
              knowledgeBaseCat?.data?.length > 0 &&
              knowledgeBaseCat?.data?.map((category) => (
                <option
                  value={category?.id}
                  selected={editData && editData?.category_id === category?.id}
                >
                  {category?.name}
                </option>
              ))}
          </Form.Select>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mt-2">
        <Form.Label as={Col} sm={3}>
          Name <span className="text-danger">*</span>
        </Form.Label>
        <Col>
          <Form.Control
            name="title"
            type="text"
            placeholder="Type Category Name"
            required
            defaultValue={editData && editData?.title}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="my-2">
        <Form.Label as={Col} sm={3}>
          Images <span className="text-danger">*</span>
        </Form.Label>
        <Col>
          <Form.Control
            name="image"
            type="file"
            placeholder="Type Category Name"
            required={!editData}
            accept="image/*"
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label as={Col} sm={3}>
          Video
        </Form.Label>
        <Col>
          <Form.Control
            name="video"
            type="file"
            placeholder="Type Category Name"
            accept="video/*"
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mt-2">
        <Form.Label as={Col} sm={3}>
          Short Description
        </Form.Label>
        <Col>
          <Form.Control
            name="short_info"
            type="text"
            placeholder="Type Short info"
            defaultValue={editData && editData?.short_info}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mt-3">
        <Form.Label as={Col} sm={3}>
          Descriptions
        </Form.Label>
        <Col>
          {/* <Form.Control */}
          {/*   as="textarea" */}
          {/*   name="info" */}
          {/*   placeholder="Type Category info" */}
          {/*   defaultValue={editData && editData?.info} */}
          {/* /> */}
          <ReactTextEditor
            setEditorData={(val) => setInfo(val)}
            defaultData={info || editData?.info}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mt-2">
        <Form.Label as={Col} sm={3}>
          Order No:{" "}
        </Form.Label>
        <Col>
          <Form.Control
            name="order_by"
            type="number"
            placeholder="Type Category Name"
            defaultValue={editData && editData?.order_by}
          />
        </Col>
      </Form.Group>

      <Form.Group className="d-flex justify-content-end my-3">
        <Form.Check
          name="status"
          label="Active"
          defaultChecked={editData ? editData?.status : 1}
        />
      </Form.Group>

      <div className="d-flex gap-2 justify-content-end">
        <Button type="button" variant="secondary">
          Close
        </Button>
        <Button type="submit" variant="primary" disabled={submitting}>
          Save Changes
        </Button>
      </div>
    </Form>
  );
};

export default KnowledgeBaseForm;
