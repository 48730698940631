import { Form } from "react-bootstrap";
import FormLabel from "../../Text/FormLabel";
import ReactInput from "../../InputList/ReactInput/ReactInput";
import ReactSelect from "../../ReactSelect/ReactSelect";
import { useFormikContext } from "formik";
import useFetch from "../../../hooks/useFetch";
import request from "../../../request/request";
import AsyncReactSelect from "../../ReactSelect/ReactAsyncSelect";

const HubManagementForm = () => {
  const { values, setFieldValue } = useFormikContext();

  const { result: countryList } = useFetch(() =>
    request.getAll("city/country/data"),
  );

  const { result: stateList } = useFetch(
    () => request.getAllById("city/state/data", values?.hub_country),
    values?.hub_country,
  );

  const { result: cityList } = useFetch(
    () => request.getAllById("hub-management/get-city", values?.hub_state),
    values?.hub_state,
  );

  const { result: hubService } = useFetch(() =>
    request.getAll("manage-by-hub/active/service"),
  );

  const { result: allUsersData } = useFetch(() =>
    request.getAll("users/role/17"),
  );

  // console.log(hubService);

  return (
    <section>
      <Form.Group className="mt-3">
        <FormLabel title="Hub Name" />
        <ReactInput name="hub_name" />
      </Form.Group>

      <Form.Group className="mt-3">
        <FormLabel title="Hub info" />
        <ReactInput name="hub_info" as="textarea" rows={3} />
      </Form.Group>

      <Form.Group className="mt-3">
        <FormLabel title="Hub Country" />
        <ReactSelect
          data={countryList}
          valueName={"id"}
          labelName={"name"}
          value={values?.hub_country}
          onChange={(val) => setFieldValue("hub_country", val)}
        />
      </Form.Group>

      <Form.Group className="mt-3">
        <FormLabel title="Hub State" />
        <ReactSelect
          data={stateList}
          valueName={"id"}
          labelName={"name"}
          value={values?.hub_state}
          onChange={(val) => setFieldValue("hub_state", val)}
        />
      </Form.Group>

      <Form.Group className="mt-3">
        <FormLabel title="Hub Area" />
        <ReactSelect
          isMulti
          data={cityList}
          valueName={"id"}
          labelName={"name"}
          value={values?.service_area}
          onChange={(val) => setFieldValue("service_area", val)}
        />
      </Form.Group>

      <Form.Group className="mt-3">
        <FormLabel title="Hub Service" />
        <ReactSelect
          isMulti
          data={hubService}
          valueName={"id"}
          labelName={"module_name"}
          value={values?.service_name}
          onChange={(val) => setFieldValue("service_name", val)}
        />
      </Form.Group>

      <Form.Group className="mt-3">
        <FormLabel title="Hub User" />
        <AsyncReactSelect
          isMulti
          data={allUsersData}
          valueName={"id"}
          labelName={"name"}
          value={values?.hub_users}
          onChange={(val) => setFieldValue("hub_users", val)}
          hasDefaultOption
        />
      </Form.Group>

      <Form.Group className="mt-3 d-flex flex-column justify-content-end ">
        <FormLabel title="Status" />
        <Form.Check
          label="Active"
          checked={!!values?.status}
          onChange={(e) => setFieldValue("status", e.target.checked)}
        />
      </Form.Group>
    </section>
  );
};

export default HubManagementForm;
