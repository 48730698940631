import axios from 'axios';
import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import UserTable from '../../Pages/UserManagement/UserListPage/UserTable';
import {getBloodGroupNumber} from "../../utils/getBloodGroup";

export default function UserByRoles() {
    const {role_id} = useParams();
    // console.log(role_id)
    const [userByRole, setUserByRole] = useState([])
    const [filterData, setFilterData] = useState([])
    const [refresh, setRefresh] = useState(false)
    useEffect(() => {
        (async function () {
            try {
                const {data} = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/users/role/${role_id}`
                );
                if (data?.data) {
                    // console.log(data.data);
                    setUserByRole(data.data)
                    setFilterData(data?.data)
                }
            } catch (error) {
                console.log(error)
            }
        })()
    }, [role_id, refresh])

    const searchHandler = (value) => {
        const trimedValue = value.trim().toLowerCase();
        setFilterData(userByRole.filter(item => (
            item?.f_name?.toLowerCase().includes(trimedValue) ||
            item?.user_detail?.blood_group?.toString()?.toLowerCase()?.includes(getBloodGroupNumber(trimedValue)) ||
            item?.mobile?.toString().includes(trimedValue) ||
            item?.registration_no.toString().includes(trimedValue)
        )))
    }

    return (
        <div>
            <UserTable users={filterData} refresh={refresh} setRefresh={setRefresh} role_id={role_id}
                       searchHandler={searchHandler}/>
        </div>
    )
}
