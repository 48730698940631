import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import { Form } from "react-bootstrap";

export default function MedicineStock() {
  const [medicineListPharmacy, setMedicineListPharmacy] = useState([]);
  const [filteredMedicineList, setFilteredMedicineList] = useState([]);

  useEffect(() => {
    (async function () {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/drugs/pharmacy`,
        );
        console.log("data", data.data);
        setMedicineListPharmacy(data.data);
        setFilteredMedicineList(data.data);
      } catch (error) {
        console.log("error while get all req list", error);
      }
    })();
  }, []);

  const columns = [
    {
      key: "registration_no",
      text: "Pharmacy ID",
      align: "center",
      sortable: true,
      cell: (data) => <span>{data?.pharmacy?.registration_no}</span>,
    },
    {
      key: "pharmacy_name",
      text: "Pharmacy Name",
      // className: 'action',
      // width: 100,
      align: "center",
      sortable: true,
      cell: (data) => <span>{data?.pharmacy?.f_name}</span>,
    },
    {
      key: "mobile",
      text: "Contact Number",
      // className: 'action',
      // width: 100,
      align: "center",
      sortable: true,
      cell: (data) => <span>{data?.pharmacy?.mobile}</span>,
    },
    {
      key: "medicine_name",
      text: "Medicine Name",
      // className: 'action',
      // width: 100,
      align: "center",
      sortable: true,
      cell: (data) => <span>{data?.drug?.name}</span>,
    },
    {
      key: "quantity",
      text: "Stock Quantity",
      align: "center",
      sortable: true,
      cell: (data) => (
        <span>
          {data?.quantity > 0
            ? `${data?.quantity} ${
                data?.drug?.unit?.symbol ? data?.drug?.unit?.symbol : ""
              }`
            : "Out of Stock"}{" "}
        </span>
      ),
    },
  ];
  const config = {
    page_size: 10,
    show_filter: false,
    show_length_menu: true,
    show_pagination: true,
    pagination: "advance",
    length_menu: [10, 50, 100],
    button: {
      extra: true,
    },
  };

  const extraButtons = [
    {
      className: "bg-transparent border-0",

      title: "Search",
      children: [
        <Form.Group controlId="formBasicText">
          <Form.Control
            type={"search"}
            placeholder={"Search"}
            // value={value}
            onChange={(e) => handlerFilter(e.target.value)}
          />
        </Form.Group>,
      ],
      onClick: (event) => {
        // console.log(event);
      },
    },
  ];

  // search/filter functions

  const handlerFilter = (value) => {
    setFilteredMedicineList(
      medicineListPharmacy.filter((item) => {
        return (
          item.drug.name.toLowerCase().includes(value.toLowerCase()) ||
          item?.pharmacy?.registration_no
            ?.toString()
            ?.includes(value.toLowerCase()) ||
          item?.pharmacy?.f_name.toLowerCase().includes(value.toLowerCase()) ||
          item?.pharmacy?.mobile?.toString()?.includes(value.toLowerCase())
        );
      }),
    );
  };

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="border p-3 rounded">
            <div className="card-box">
              <h6 className="mb-0 text-uppercase">
                All Pharmacy Medicine List
              </h6>
              <div className="col">{/* Modal */}</div>
            </div>

            <hr />

            <ReactDatatable
              config={config}
              records={filteredMedicineList}
              columns={columns}
              extraButtons={extraButtons}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
