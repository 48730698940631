import React, {useState} from 'react';
import FilterForm from "../../components/FinancialReports/FilterForms";
import axios from "axios";
import CashFlowDetails from "../../components/FinancialReports/CashFlowDetails";

const CashFlowStatements = () => {

    const [paymentList, setPaymentList] = useState(null);

    const filterController = async (formData) => {
        try {
            console.log(formData);
            const {data} = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/financial-reports/filter-payment`, formData);

            if (data.status === 'OK') {
                setPaymentList(data.data)
            }
            console.log(data)

        } catch (e) {
            console.log(e);
        }

    }

    // console.log(paymentList)

    return (
        <section>
            <div className="card">
                <div className="card-body">
                    <div className="border p-3 rounded">
                        <div className="card-box">
                            <h6 className="mb-0 text-uppercase">Cash Flow Statement List</h6>

                        </div>

                        <hr/>

                        <div>

                            <FilterForm apiHandler={filterController} isServiceSearch/>
                        </div>

                        <div>
                            {
                                paymentList ? <CashFlowDetails paymentList={paymentList}/> :
                                    <h6 className={'text-center text-uppercase mt-5'}>Search With Any date range.</h6>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
};

export default CashFlowStatements;