import React from "react";
import ReactDataTable from "@ashvin27/react-datatable";
import { Form } from "react-bootstrap";

const ReactTable = ({ data, columns, isLoading }) => {
  const handlerFilter = () => {};

  const config = {
    page_size: 10,
    show_filter: false,
    show_length_menu: true,
    show_pagination: true,
    pagination: "advance",
    length_menu: [10, 50, 100],
    button: {
      extra: true,
    },
  };

  const extraButtons = [
    {
      className: "bg-transparent border-0",

      title: "Search",
      children: [
        <Form.Group controlId="formBasicText">
          <Form.Control
            type={"search"}
            placeholder={"Search"}
            // value={value}
            onChange={(e) => handlerFilter(e.target.value)}
          />
        </Form.Group>,
      ],
      onClick: (event) => {
        // console.log(event);
      },
    },
  ];

  return (
    <div>
      <ReactDataTable
        config={config}
        records={data}
        columns={columns}
        extraButtons={extraButtons}
        loading={isLoading}
      />
    </div>
  );
};

export default ReactTable;

