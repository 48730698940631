// this hooks for fetching data , specially when get request when useEffect is needed ;

import { useEffect, useState } from "react";

function useFetchData(func, dep) {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isSuccess, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    (async function fetchData() {
      try {
        const res = await func();
        setData(res?.data);
        setSuccess(true);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })(); // self calling functions
  }, [refresh, dep]);

  return { data, isLoading, isSuccess, error, setRefresh };
}

export default function useFetch(func, dep) {
  const { data, isLoading, isSuccess, error, setRefresh } = useFetchData(
    func,
    dep,
  );

  return { result: data, isLoading, isSuccess, error, setRefresh };
}
