import React from "react";
import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";

const NameInfoFormFormik = ({ firstFieldName }) => {
  const { values, setFieldValue, handleChange } = useFormikContext();
  // console.log(values);

  return (
    <>
      <Form.Group className="mb-2" controlId="formParentCategoryName">
        <Form.Label>
          Name
          <span className="text-danger">*</span>
        </Form.Label>
        {/* Brand name is for brand category form . */}
        <Form.Control
          type="text"
          name={firstFieldName}
          value={values[firstFieldName]}
          onChange={handleChange}
        />
        <Form.Control.Feedback type="invalid">
          Please provide a valid name.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-2" controlId="formInfo">
        <Form.Label>Info</Form.Label>
        <Form.Control
          as="textarea"
          name="info"
          rows={4}
          maxLength={201}
          value={values?.info}
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group className="mb-2 d-flex align-items-center justify-content-end">
        <div className="d-flex align-items-center justify-content-end">
          <Form.Check
            type="checkbox"
            name="status"
            id="flexCheckChecked"
            checked={values?.status === 1}
            onChange={(e) => setFieldValue("status", e.target.checked ? 1 : 0)}
            label="Active"
          />
        </div>
      </Form.Group>
    </>
  );
};

export default NameInfoFormFormik;
