import React from 'react';
import {Modal} from "react-bootstrap";
import ModuleForm from "./ModuleForm";
import axios from "axios";
import toast from "react-hot-toast";

const AddNewModuleModal = ({isOPen, onClose, activity, refresh}) => {

    const createNewModuleHandler = async (formData) => {
        try {
            const {data} = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/module`, formData);

            if (data) {
                toast.success("Module created successfully")
                onClose();
                refresh();
            }

        } catch (err) {
            console.log(err);
        }

    }


    return (
        <Modal show={isOPen} onHide={onClose}>

            <Modal.Header>
                <h6> Add New Module</h6>
            </Modal.Header>

            <Modal.Body>
                <ModuleForm activity={activity} OnClose={onClose} apiHandler={createNewModuleHandler}/>
            </Modal.Body>

        </Modal>
    );
};

export default AddNewModuleModal;