import { Row, Col } from "react-bootstrap";

const UserProfile = ({ userData }) => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const runningSubscription = userData?.user_subscriptions?.find((item) => {
    const expiredDate = new Date(item?.expiry_date);

    // Check if expiredDate is a valid date
    if (!isNaN(expiredDate)) {
      console.log("ex", expiredDate);

      // Set the time to compare to midnight of the expiry date
      expiredDate.setHours(0, 0, 0, 0);

      return expiredDate >= currentDate;
    } else {
      // Handle cases where expiry_date is not a valid date
      console.error("Invalid expiry date:", item?.expiry_date);
      return false; // Assuming we don't want to include invalid dates
    }
  });

  return (
    <div className="card shadow-sm border-0 overflow-hidden">
      <div className="card-body row">
        <Col sm={12} md={6}>
          <div className="profile-avatar text-center">
            {!userData || !userData?.image ? (
              <img
                src="/userimg.png"
                className="rounded-circle shadow"
                width={120}
                height={120}
                alt=""
              />
            ) : (
              <img
                src={`${process.env.REACT_APP_UPLOAD_URL}/users/${userData?.image}`}
                className="rounded-circle shadow"
                width={120}
                height={120}
                alt=""
              />
            )}
          </div>

          <div className="text-center mt-4">
            <h6 className="mb-1">Reg No. {userData?.registration_no}</h6>
            <h4 className="mb-1">
              {userData?.f_name} {userData?.l_name}{" "}
              {runningSubscription ? (
                <i className="bi bi-patch-check-fill text-primary"></i>
              ) : null}
            </h4>
            <h6 className="mb-1">Email: {userData?.email}</h6>
            <h6 className="mb-3">Mobile: {userData?.mobile}</h6>
            <h6 className="mb-3">Date Of Birth: {userData?.date_of_birth}</h6>
            <p className="mb-0">
              {" "}
              <b> Location :</b> {userData?.address_1} {userData?.address_2}{" "}
            </p>
            <div className="mt-4" />
          </div>

          {runningSubscription ? (
            <div className="mt-3 text-center">
              <p className="mb-0">
                {" "}
                <b> Subscription Package</b>{" "}
              </p>

              <p className="mb-0 text-center">
                {runningSubscription?.subscription_type}
              </p>
              <p className="mb-0 mt-1 text-center bg-primary text-white w-75 mx-auto rounded-1">
                {runningSubscription?.package?.name}
              </p>
              <p className="text-center mt-1">
                Valid Till : {runningSubscription?.expiry_date?.split("T")[0]}
              </p>
            </div>
          ) : null}
        </Col>

        <Col sm={12} md={6}>
          <ul className="list-group list-group-flush mt-4">
            <li className="list-group-item d-flex justify-content-between align-items-center bg-transparent border-top">
              Contact Person
              <span className="badge bg-light text-dark">
                {userData?.contact_person}
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center bg-transparent border-top">
              NID
              <span className="badge bg-light text-dark">{userData?.nid}</span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center bg-transparent border-top">
              Status
              <span className="badge bg-primary rounded-pill">
                {userData?.status === 1 ? "Active" : "Inactive"}
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center bg-transparent">
              Credit Limit
              <span className="badge bg-primary rounded-pill">
                BDT{" "}
                {`${userData?.credit_limit === undefined ? 0 : userData?.credit_limit}`}
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center bg-transparent">
              Commission Rate
              <span className="badge bg-primary rounded-pill">
                {userData?.commission_rate}%
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center bg-transparent">
              Wallet
              <span className="badge bg-primary rounded-pill">
                {userData?.wallet}
              </span>
            </li>
          </ul>
          <hr />
          <div className="text-start">
            <h5 className="">Remarks</h5>
            <p className="mb-0">{userData?.remarks}</p>
          </div>
        </Col>
      </div>
    </div>
  );
};

export default UserProfile;
