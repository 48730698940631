import React, {useContext, useEffect, useState} from 'react';
import {UserContext} from "../../Context/UserContextAPI";
import axios from "axios";
import formatDateTime from "../../utils/DateTimeFormate";
import {Button, Form} from "react-bootstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import RoomInfo from "./RoomInfo";
import ServiceChargeinfo from "./ServiceChargeinfo";

const TreatmentRequestList = () => {
    const [getAllTreatmentRequest, setGetAllTreatmentRequest] = useState([]);
    const [filteredTreatmentRequest, setFilteredTreatmentRequest] = useState([]);
    const [modalOpen, setModalOpen] = useState('');
    const [selectedData, setSelectedData] = useState([]);

    const {accessPerm} = useContext(UserContext);

    useEffect(() => {
        (async function () {
            const abort = new AbortController();
            try {
                const {data} = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/request/treatments/admin/get-all`,
                    {
                        signal: abort.signal
                    }
                );
                
                setGetAllTreatmentRequest(data.data);
                setFilteredTreatmentRequest(data.data);
            } catch (error) {
                console.log("error while get all req list", error);
            }
            return () => abort.abort();
        })();
    }, []);

    const columns = [
        {
            key: "req_no",
            text: "Request No:",
            align: "center",
            sortable: true,
        },
        {
            key: "reg_no",
            text: "Registration No:",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: false,
            cell: (data) => {
                return <span>{data?.requester?.registration_no}</span>;
            },
        },
        {
            key: "req_by",
            text: "Request By",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => {
                return <span>{data?.requester?.f_name}</span>;
            },
        },
        {
            key: "mobile",
            text: "Mobile",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => {
                return <span>{data?.requester?.mobile}</span>;
            },
        },

        {
            key: "hospital_name",
            text: "Hospitals Name",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: true,
            cell: (data) => {
                return <span>{data?.hospital?.f_name}</span>;
            },
        },
        {
            key: "hospital_address",
            text: "Hospital Address",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: true,
            cell: (data) => {
                return <span>
                    {data?.hospital?.address_1}, {data?.hospital?.user_detail?.city?.name} , {data?.hospital?.user_detail?.state?.name} , {data?.hospital?.user_detail?.country?.name}
                </span>;
            },
        },
        {
            key: "hospital_mobile",
            text: "Hospital Mobile",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: true,
            cell: (data) => {
                return <span>{data?.hospital?.mobile}</span>;
            },
        },
        {
            key: "treatment_date",
            text: "Treatment Date",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            // cell: data => <span>{dateFormate(data.appointment_date)}</span>
        },
        {
            key: "specification_fields",
            text: "Specification Fields",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: true,
            cell: (data) => {
                return <span>{data?.specific_fields.map(spec => spec.service_cat_info?.name)?.join(',')}</span>;
            },
        },
        {
            key: "room_info",
            text: "Room Information",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: true,
            cell: (data) => {
                return <Button
                    onClick={() => {
                        setModalOpen('room_info');
                        setSelectedData([data.room])
                    }}
                    size='sm'
                >
                    See Details
                </Button>;
            },
        },
        {
            key: "service_charges",
            text: "Service Charges Info",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: true,
            cell: (data) => {
                return <Button
                    variant='primary'
                    onClick={() => {
                        const serviceCharge = data.service_charges.map(item => item.service_charge_info)
                        setModalOpen('service');
                        setSelectedData(serviceCharge)
                    }}
                    size='sm'
                >
                    See Details
                </Button>;
            },
        },
        {
            key: "total_amount",
            text: "Total Amount",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: true,
            cell: (data) => {
                return <span>{data?.total_amount} TK</span>;
            },
        },
        {
            key: "paid_amount",
            text: "Paid Amount",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: true,
            cell: (data) => {
                return <span>{data?.paid_amount ? `${data?.paid_amount} TK` : ''}</span>;
            },
        },
        {
            key: "due_amount",
            text: "Due Amount",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: true,
            cell: (data) => {
                return <span>{data?.due_amount ? `${data?.due_amount} TK` : ''}</span>;
            },
        },
        {
            key: "createdAt",
            text: "Request Date",
            // className: 'action',
            // width: 100,
            align: "center",
            // sortable: true,
            cell: (data) => {
                return <span>{formatDateTime(data.createdAt)}</span>;
            },
        },

        {
            key: "status",
            text: "status",
            // className: 'action',
            // width: 100,
            align: "center",
            sortable: false,
            cell: (data) => {
                return (
                    <p>
                        {data.status === 0
                            ? "Pending"
                            : data.status === 1
                                ? <span className='text-primary'>Requested</span>
                                : data.status === 2
                                    ? <span className='text-info'>Accepted</span>
                                    : data.status === 3
                                        ? <span className='text-danger'>Canceled</span>
                                        : data.status === 4
                                            ? <span className={'text-purple'}> Confirmed</span>
                                            : data.status === 5
                                                ? <span className={'text-success'}>Completed</span>
                                                :
                                                "unknown"
                        }
                    </p>
                );
            },
        },
        // {
        //   key: "action",
        //   text: "Action",
        //   // className: 'action',
        //   width: 120,
        //   align: "center",
        //   sortable: false,
        //   cell: (data) => {
        //     return (
        //       <>
        //         {accessPerm(39, 8) && data.status === 5 && (
        //           <Button
        //             onClick={() => {
        //               setShowUploadReport(true);
        //               setSingleItem(data);
        //             }}
        //             variant="success"
        //           >
        //             Upload Report
        //           </Button>
        //         )}
        //       </>
        //     );
        //   },
        // },
    ];
    const config = {
        page_size: 10,
        show_filter: false,
        show_length_menu: true,
        show_pagination: true,
        pagination: "advance",
        length_menu: [10, 50, 100],
        button: {
            extra: true,
        },
    };

    // search component

    const extraButtons = [
        {
            className: "bg-transparent border-0",

            title: "Search",
            children: [
                <Form.Group controlId="formBasicText">
                    <Form.Control
                        type={"search"}
                        placeholder={"Search"}
                        // value={value}
                        onChange={(e) => handlerFilter(e.target.value)}
                    />
                </Form.Group>,
            ],
            onClick: (event) => {
                // console.log(event);
            },
        },
    ];

    // search/filter functions

    const handlerFilter = (value) => {
        setFilteredTreatmentRequest(
            getAllTreatmentRequest.filter((item) => {
                return (
                    item?.req_no?.toString()?.includes(value.toLowerCase()) ||
                    item?.requester?.f_name
                        .toLowerCase()
                        .includes(value.toLowerCase()) ||
                    item?.hospital?.f_name
                        .toLowerCase()
                        .includes(value.toLowerCase()) ||
                    item?.requester?.registration_no
                        ?.toString()
                        ?.includes(value.toLowerCase()) ||
                    item?.requester?.mobile
                        ?.toString()
                        ?.includes(value.toLowerCase())
                );
            }),
        );
    };

    return (
        <section>
            <div className="card">
                <div className="card-body">
                    <div className="border p-3 rounded">
                        <div className="card-box">
                            <h6 className="mb-0 text-uppercase">All Treatments Request List</h6>

                        </div>

                        <hr/>

                        <div>
                            <ReactDatatable
                                config={config}
                                records={filteredTreatmentRequest}
                                columns={columns}
                                extraButtons={extraButtons}

                            />
                        </div>
                    </div>
                </div>
            </div>

            {/*Modals */}

            {
                modalOpen === "room_info" &&
                <RoomInfo isOPen={modalOpen === 'room_info'} onClose={() => setModalOpen('')} data={selectedData}/>
            }

            {
                modalOpen === "service" &&
                <ServiceChargeinfo isOPen={modalOpen === 'service'} onClose={() => setModalOpen('')}
                                   data={selectedData}/>
            }


        </section>
    );
};

export default TreatmentRequestList;